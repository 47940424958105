<template>
  <div class="detail">
    <div class="title">
      Frequency
    </div>
    <dropdown
      v-if="loaded && !nextSchedule && !auditionMode"
      :options="fakeOptions"
      :selected="selectedOption"
      :read-only="readOnly"
      @onChange="doFakeOnChange"
    />
    <dropdown
      v-else-if="!(disabled || unmodifiable || auditionMode)"
      :options="frequencies"
      :selected="selectedOption"
      :confirmation-options="confirmationOptions"
      :read-only="readOnly"
      @onChange="onChange"
    />
    <span
      v-if="!auditionMode && nextSchedule && (disabled || unmodifiable)"
      v-tooltip="disabled ? tooltipData : {}"
      class="disabled-frequency"
    >
      {{ selectedFrequency.text }}
    </span>
    <span
      v-if="auditionMode"
      class="disabled-frequency"
    >
      {{ auditionFrequencyText }}
    </span>
  </div>
</template>
<style>

</style>
<script>
import { mapGetters } from 'vuex'
import Dropdown from '@/components/common/Dropdown'

export default {
  name: 'MeasureFrequency',
  components: {
    Dropdown
  },
  props: [
    'selected',
    'measureId',
    'assignmentUserId',
    'sessionDate',
    'disabled',
    'unmodifiable',
    'clientMeasureId',
    'nextSchedule',
    'readOnly',
    'latestCompletedMeasure'
  ],
  data () {
    return {
      loaded: false,
      auditionFrequencyText: 'None',
      selectedOption: this.selected,
      tooltipData: {
        content: this.$t('measureLinkedWithAvisitCantReschedule'),
        placement: 'right',
        classes: ['no-arrow']
      },
      confirmationOptions: [
        { value: 1, text: this.$t('applyToAll') }
      ],
      frequencies: []
    }
  },
  computed: {
    ...mapGetters({
      settings: 'generalSettings',
      // frequencies: 'getFrequencies',
      auditionMode: 'getAuditionMode',
      details: 'measureLibraryDetail'
    }),
    selectedFrequency () {
      let selected = null
      for (let i = 0, len = this.frequencies.length; i < len; i++) {
        const item = this.frequencies[i]
        if (item.value === this.selectedOption) {
          selected = item
          break
        }
      }
      return selected
    }
  },
  watch: {
    selected (newFrequency) {
      this.selectedOption = Number(newFrequency)
    }
  },
  created () {
    this.frequencies = this.settings.scheduling_frequency_options
    if (this.nextSchedule) {
      return true
    }

    this.setFrequencyOptionSelectDefaults()
    this.loaded = true
  },
  methods: {
    onChange (selected) {
      const data = {
        client_measure_id: this.clientMeasureId,
        client_clinic_assignment_user_id: this.assignmentUserId,
        frequency: selected.option.value,
        session_date_time: this.sessionDate,
        apply_to_all_measures_in_session: false
      }

      if (selected.confirmation) {
        data.apply_to_all_measures_in_session = true
      }

      this.$store.dispatch('CHANGE_MEASURE_FREQUENCY', data).then(() => {
        this.selectedOption = selected.option.value
        this.$toast.success({ message: this.$t('measureFrequencyAdjusted') })
      })
    },
    doFakeOnChange (selected) {
      this.$emit('changedFakeFrequency', selected)
    },
    setFrequencyOptionSelectDefaults () {
      return this.auditionMode ? this.setDefaultFrequencySelectedForAudition() : this.setDefaultFrequencySelectedForOverview()
    },
    setDefaultFrequencyOptions () {
      const fakeOptions = []
      for (let i = 0, len = this.frequencies.length; i < len; i++) {
        fakeOptions.push(this.frequencies[i])
      }
      fakeOptions.unshift({ text: this.$t('none'), value: 999 })
      this.fakeOptions = fakeOptions
    },
    setDefaultFrequencySelectedForAudition () {
      if (this.details && this.details.frequency_reference && this.details.frequency_reference.text) {
        this.auditionFrequencyText = this.details.frequency_reference.text
      }
    },
    setDefaultFrequencySelectedForOverview () {
      this.setDefaultFrequencyOptions()
      if (this.latestCompletedMeasure && this.latestCompletedMeasure.frequency) {
        this.selectedOption = Number(this.latestCompletedMeasure.frequency.value)
        this.doFakeOnChange({ option: { value: this.selectedOption } })
      }
    }
  }
}
</script>
<style scoped lang="scss">
  $spacingTopBottom: 5px;

  .disabled-frequency {
    cursor: not-allowed;
    display: inline-block;
    padding: 5px 0;
  }
</style>
