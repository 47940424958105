<template>
  <!-- PDF template  -->
  <div
    v-if="currentRouteName === 'ClinicianPatientMeasurePDF' || currentRouteName === 'ClinicianPatientPDF'"
    class="measure-chart-item"
  >
    <div
      v-if="readyChart && chartConfig.sessions_progress_results"
      class="measure-chart"
    >
      <bar-chart
        v-if="chartConfig.sessions_progress_results.length === 1"
        :data="chartConfig"
        :num-decimal-digits="chartConfig.num_decimal_digits"
        :colors="colors"
      />
      <progress-bar-chart
        v-else
        ref="progressBar"
        :scale="scale"
        :chart-index="chartIndex"
        :colors="colors"
        :data="chartConfig"
        :is-multi-series-chart="isMultiSeriesChart"
        :num-decimal-digits="chartConfig.num_decimal_digits"
      />
    </div>
    <div
      v-if="readyChart && !chartConfig.sessions_progress_results"
      class="measure-chart"
    >
      <bar-chart
        v-if="measureData.sessions_completed.length === 1"
        :data="chartConfig"
        :num-decimal-digits="chartConfig.num_decimal_digits"
        :colors="colors"
      />
      <progress-bar-chart
        v-else
        ref="progressBar"
        :scale="scale"
        :chart-index="chartIndex"
        :colors="colors"
        :data="chartConfig"
        :is-multi-series-chart="isMultiSeriesChart"
        :num-decimal-digits="chartConfig.num_decimal_digits"
      />
    </div>
  </div>
  <!-- END PDF template  -->
  <div
    v-else
    class="measure-chart-item"
  >
    <measure-chart-header
      v-if="measureData.sessions_completed.length"
      :more-info="chartConfig.chart_tooltip_text"
      :show-all-button="showAllButton"
      :hideall-button="hideallButton"
      :show-menu="measureData.sessions_completed.length > 1"
      @setShowAll="setShowAll"
    />
    <div
      v-if="readyChart"
      class="measure-chart"
    >
      <bar-chart
        v-if="measureData.completedDataWithScores === 1"
        :data="chartConfig"
        :num-decimal-digits="chartConfig.num_decimal_digits"
        :colors="colors"
      />
      <progress-bar-chart
        v-else
        ref="progressBar"
        :scale="scale"
        :chart-index="chartIndex"
        :colors="colors"
        :data="chartConfig"
        :num-decimal-digits="chartConfig.num_decimal_digits"
        @showAllButton="showAllButtonStatus"
        @hideallButton="hideallButtonStatus"
      />
    </div>
  </div>
</template>

<script>
import MeasureChartHeader from '@/components/clinician/patients/measures/charts/MeasureChartHeader'
import ProgressBarChart from '@/components/clinician/patients/measures/charts/ProgressBarChart'
import BarChart from '@/components/clinician/patients/measures/charts/BarChart'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { mapGetters } from 'vuex'
const ALLOWED_SCALES = ['month', 'week']

export default {
  name: 'MeasureChart',
  components: {
    MeasureChartHeader,
    ProgressBarChart,
    BarChart
  },
  mixins: [DateTimeHelper],
  props: ['measureData', 'chartConfig', 'chartIndex'],
  data () {
    return {
      colors: ['#7cb5ec', '#90ed7d', '#f7a35c', '#8085e9', '#e4d354', '#2f7ed8', '#c42525', '#a6c96a', '#E6B333', '#3366E6', '#B34D4D', '#80B300', '#E6B3B3', '#66991A', '#FF99E6', '#CCFF1A', '#FF6633', '#00B3E6'],
      showAllButton: true,
      hideallButton: true,
      readyChart: false
    }
  },
  computed: {
    ...mapGetters({
      settings: 'generalSettings'
    }),
    isMultiSeriesChart () {
      return this.measureData.chart[0].progress_chart.series.length > 1
    },
    scale () {
      return this.settings.x_axis_scale && ALLOWED_SCALES.includes(this.settings.x_axis_scale) ? this.settings.x_axis_scale : 'month'
    },
    currentRouteName () {
      return this.$route.name
    }
  },
  created () {
    setTimeout(() => {
      this.readyChart = true
    }, 210)
  },
  methods: {
    setShowAll (value) {
      this.$refs.progressBar.setShowAll(value)
      this.$refs.progressBar.legendItemClick()
    },
    showAllButtonStatus (value) {
      this.showAllButton = value
    },
    hideallButtonStatus (value) {
      this.hideallButton = value
    }
  }
}
</script>
