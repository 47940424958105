<template>
  <div class="box-with-action">
    <slot
      class="box-with-action-box"
      name="content"
    />
    <div v-if="customActions.length">
      <i
        v-for="(action, index) in customActions"
        :key="index"
        v-tooltip="action.tooltip"
        class="smaller-icon"
        :class="action.icon"
      />
    </div>
    <i
      class="box-with-action-icon"
      :class="finalClass"
      @click="emitClicked"
    />
  </div>
</template>

<script>
export default {
  name: 'BoxWithAction',
  props: {
    iconClass: {
      type: String,
      default: 'fal fa-times'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    customActions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    finalClass () {
      return this.disabled ? `${this.iconClass} disabled` : this.iconClass
    }
  },
  methods: {
    emitClicked () {
      if (!this.disabled) {
        this.$emit('wasClicked')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.box-with-action {
  display: flex;
  align-items: center;
  i:not(.box-with-action-icon) {
    margin-right: 5px;
  }
  &-icon{
    color: #83909d;
    padding: 0 5px;
    display: none;
    &:hover {
      cursor: pointer;
    }
    &.disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
  &:hover {
    .box-with-action-icon{
      display: block;
    }
  }
}
</style>
