<template>
  <v-popover
    class="more-info"
    placement="bottom"
    trigger="manual"
    :open="show"
    :auto-hide="true"
    popover-class="more-info-tooltip"
    @hide="show = false"
  >
    <i
      :class="iconClass"
      @click="show = true"
    />
    <template slot="popover">
      <div class="popover-close">
        <p>{{ title }}</p>
        <i
          v-if="closeButton"
          class="fal fa-times"
          @click="show = false"
        />
      </div>
      <div
        class="popover-content"
        v-html="data"
      />
    </template>
  </v-popover>
</template>

<style>

</style>
<script>

export default {
  name: 'MoreInfoTooltip',
  components: {
  },
  props: ['data', 'reverseIcon', 'title', 'closeButton'], // eslint-disable-line vue/require-prop-types
  data: () => {
    return {
      show: false
    }
  },
  computed: {
    iconClass () {
      const iconClass = this.reverseIcon ? 'fal' : 'fa'
      return `${iconClass} fa-info-circle`
    }
  }
}
</script>
<style scoped>
  .fa-info-circle {
    color: #2068AC;
  }
  .more-info {
    display: inline-block;
  }
</style>
