<template>
  <div>
    <div v-if="!clientMeasureId && frequency === null">
      <span
        v-tooltip="tooltipData"
        :class="{ 'disabled': !readOnly }"
      >
        {{ $t('none') }}
      </span>
    </div>
    <div v-else>
      <datepicker
        v-if="!isDelete"
        v-model="measureDate"
        class="value"
        format="MMM dd, yyyy"
        :input-class="{inactive : !clinicianAssignments}"
        :placeholder="$t('none')"
        :highlighted="getHighlightedDates()"
        :disabled="!clinicianAssignments"
        :disabled-dates="disabledDates"
        :title="!clinicianAssignments ? $t('noCliniciansAssigned') : ''"
        @selected="showConfirmation"
        @opened="warnIfInactive"
        @changedMonth="changedMonth"
      />
      <div
        v-if="tooltip.show"
        class="tooltip vue-tooltip-theme calendar-tooltip"
        role="tooltip"
        :style="getTooltipStyles"
      >
        <div
          class="tooltip-inner"
          v-html="getTooltipContent()"
        />
      </div>
      <div
        v-if="showConfirm"
        class="wrapper opened"
        :class="{'is-delete': isDelete}"
      >
        <div class="option">
          {{ measureDateFormatter }}
        </div>
        <div class="division" />
        <div
          v-if="showConfirm"
          class="confirm"
        >
          <div class="confirm-list">
            <label>
              <input
                v-if="!getSchedulingTypeIsVisit(frequencyInformation)"
                v-model="confirmationSelected"
                type="checkbox"
                :value="true"
              >
              <span>{{ confirmText }}</span>
            </label>
          </div>
          <button
            class="btn btn-outline-secondary"
            @click="triggerCancel"
          >
            Cancel
          </button>
          <button
            class="btn btn-secondary"
            @click="triggerConfirm"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { SchedulingType } from '@/mixins/scheduling/SchedulingType'
import { PatientHelper } from '@/mixins/PatientHelper'
import { CalendarHelper } from '@/mixins/CalendarHelper'
import { DELETE_SCHEDULED_MEASURE } from '@/store/modules/clinicians/constants.js'
import moment from 'moment'

export default {
  name: 'MeasureDueDate',
  components: {
    Datepicker
  },
  mixins: [
    DateTimeHelper,
    PatientHelper,
    CalendarHelper,
    SchedulingType
  ],
  props: [
    'clientMeasureId',
    'assigmentUserId',
    'date',
    'frequency',
    'measureId',
    'isDelete',
    'sessionDate',
    'ccauid',
    'readOnly',
    'frequencyInformation'
  ],
  data: function () {
    return {
      confirmationSelected: false,
      showConfirm: this.isDelete,
      needScheduleNew: this.date === 'scheduleNew' || this.isDelete,
      measureDate: null,
      disabledDates: {
        to: moment().subtract(1, 'days').toDate()
      },
      initialTooltipData: {
        content: this.$t('needToSelectAValidFrequency'),
        placement: 'right',
        classes: ['no-arrow']
      }
    }
  },
  computed: {
    ...mapGetters({
      frequencies: 'getFrequencies',
      schedulingOptions: 'getSchedulingOptions',
      patient: 'getSinglePatient',
      activeAssignment: 'getActiveAssignment',
      clinicianAssignments: 'getClinicianAssignments'
    }),
    tooltipData () {
      if (this.readOnly) {
        return false
      }

      return this.initialTooltipData
    },
    confirmText () {
      const dayVisit = this.getVisitForDay(this.measureDateToSave)
      const schedulingTypeVisit = this.getSchedulingTypeIsVisit(this.frequencyInformation)

      // Visit based measure and day contains a visit.
      if (schedulingTypeVisit && dayVisit) {
        return this.$t('linkToVisit', { date: moment.utc(this.measureDateToSave).format('MMM DD') })
      }

      // Visit based measure and day do not contains a visit.
      if (schedulingTypeVisit && !dayVisit) {
        return this.$t('wontBeLinkedToVisit')
      }

      // Normal
      return this.$t('applyToAll')
    },
    measuresAssigned () {
      return this.schedulingOptions.measures_assigned ? this.schedulingOptions.measures_assigned : []
    },
    appointments () {
      return this.schedulingOptions.appointments ? this.schedulingOptions.appointments : []
    },
    measureDateFormatter () {
      return this.isDelete ? this.$t('none') : this.$fromCalendarToLocal(this.measureDate, 'MMMM DD, YYYY')
    },
    measureDateToSave () {
      // Return just the local date, it'll be converted to UTC before sending to the server.
      return this.$fromCalendarToLocal(this.measureDate, 'YYYY-MM-DD')
    }
  },
  watch: {
    date (newData) {
      this.measureDate = this.$toLocal(newData, 'YYYY/MM/DD HH:mm:ss')
    }
  },
  created () {
    if (this.date && this.date !== 'scheduleNew') {
      this.measureDate = this.$toLocal(this.date, 'YYYY/MM/DD HH:mm:ss')
    }
  },
  destroyed () {
    document.body.removeEventListener('mouseover', this.showTooltip, false)
  },
  methods: {
    getVisitForDay (date) {
      const dateString = moment.utc(date).format('YYYY-MM-DD')
      return this.appointments.find(a => moment(a.visit_date_time).format('YYYY-MM-DD') === dateString)
    },
    showConfirmation () {
      this.showConfirm = true
    },
    triggerCancel () {
      this.measureDate = this.date ? this.$toLocal(this.date, 'YYYY/MM/DD HH:mm:ss') : null
      this.showConfirm = false
      this.confirmationSelected = false
      this.$emit('cancel')
    },
    triggerConfirm () {
      this.showConfirm = false
      return this.isDelete ? this.deleteAssignedMeasure() : this.scheduleOrChangeDueDate()
    },
    scheduleOrChangeDueDate () {
      this.$reactivateIfInactive(this.$store.getters.getActiveAssignment).then(() => {
        return this.needScheduleNew ? this.scheduleMeasure() : this.changeMeasureDueDate()
      })
    },
    deleteAssignedMeasure () {
      const params = {
        clientMeasureId: this.clientMeasureId,
        apply_to_all_measures_in_session: this.confirmationSelected,
        session_date_time: this.sessionDate,
        client_clinic_assignment_user_id: this.ccauid
      }

      const loader = this.$loading.show()
      this.$store.dispatch(DELETE_SCHEDULED_MEASURE, params).then(() => {
        this.$emit('deleted')
        this.$refreshActiveAssignmentData()
      }).finally(() => loader.hide())
    },
    scheduleMeasure () {
      const data = {
        client_clinic_assignment_user_id: this.assigmentUserId,
        measure_id: this.measureId,
        due_date: this.measureDateToSave,
        frequency: this.frequency
      }
      this.confirmationSelected = false
      if (this.showProgramsUserIsMember) {
        data.programIds = [this.activePatientProgramsAssignation[0].program.id]
      }
      return this.dispatch('SCHEDULE_MEASURE', data)
    },
    changeMeasureDueDate () {
      const dayVisit = this.getVisitForDay(this.measureDateToSave)
      const schedulingTypeVisit = this.getSchedulingTypeIsVisit(this.frequencyInformation)
      const data = {
        client_clinic_assignment_user_id: this.assigmentUserId,
        client_measure_id: this.clientMeasureId,
        due_date: this.measureDateToSave
      }

      data.apply_to_all_measures_in_session = schedulingTypeVisit ? false : this.confirmationSelected

      if (schedulingTypeVisit && dayVisit) {
        data.appointment_id = dayVisit.id
      }
      this.confirmationSelected = false
      if (this.showProgramsUserIsMember) {
        data.programIds = [this.activePatientProgramsAssignation[0].program.id]
      }
      return this.dispatch('CHANGE_MEASURE_DUE_DATE', data)
    },
    dispatch (action, data) {
      const toastText = this.$t('measureDueDateAdjusted')
      const promise = this.$store.dispatch(action, data)
      promise.then((result) => {
        this.$refreshActiveAssignmentData()
        this.getRespondentLatestSession()
        this.$store.dispatch('GET_PATIENTS_MEASURES', this.assigmentUserId)
        this.needScheduleNew = false
        this.$toast.success({ message: toastText })
        if (this.assigmentUserId) {
          this.$store.dispatch('GET_SCHEDULING_OPTIONS', { ccauId: this.assigmentUserId }).then(() => {
            this.calcCalendarItemsByDate()
          })
        }
        if (parseInt(result.reminder_sent) === 1 && (this.patient.mobile_notification || this.patient.email_notification)) {
          this.$toast.success({ message: this.$t('reminderSent') })
        }
      })
      return promise
    },
    warnIfInactive () {
      this.initPopoverAndDate()
      this.calcCalendarItemsByDate()
      if (this.$store.getters.getActiveAssignment.status === 'inactive') {
        this.$toast.warning({ message: this.$t('willActivatePatient', { action: this.$t('schedulingMeasure') }) })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  $spacingTopBottom: 5px;
  $spacingLeftRight: 8px;
  $border: 1px;
  $width: 240px;

  .vdp-datepicker.value {
    padding-top: 4px;
  }

  .wrapper {
    position: absolute;
    width: $width;
    background: #FFF;
    border-radius: 3px;
    border: solid $border #899cb5;
    padding: $spacingTopBottom $spacingLeftRight;
    margin-top: ($spacingTopBottom + $border + 15) * -1;
    margin-left: ($spacingLeftRight + $border) * -1;
    z-index: 2;
    &.is-delete {
      margin-top: 30px;
    }
  }

  .division {
    background: #e8eef4;
    height: 2px;
    width: 100%;
    padding-left: $spacingLeftRight;
    padding-right: $spacingLeftRight;
    margin-top: $spacingTopBottom;
    margin-bottom: $spacingTopBottom;
  }

  .option {
    display: block;
    cursor: pointer;
    padding: $spacingTopBottom 0;
  }

  .disabled {
    cursor: not-allowed;
    display: inline-block;
    padding: 5px 0;
  }
</style>
