<template>
  <div class="detail">
    <AdministeredMeasureCompletionData
      v-if="measureData.clinical_administered && latestCompletedMeasure && latestCompletedMeasure.updated_by"
      :assignment="assignment"
      :measures="ordererSchedules.length"
      :measure="measureData"
      :measure-data="latestCompletedMeasure"
    />
    <div v-if="ordererSchedules.length">
      <div
        v-for="(nextSchedule, index) in ordererSchedules"
        :key="index"
        class="nex-due-dates-container"
        :class="{'clinical-administered': measureData.clinical_administered}"
      >
        <ClinicalAdministeredButton
          v-if="measureData.clinical_administered"
          :is-update="isClinicalAdministeredUpdate"
          :measure="getMeasureDataParams(nextSchedule)"
        />
        <template v-else>
          <measure-frequency
            v-if="nextSchedule.frequency_information"
            :read-only="readOnly"
            :next-schedule="nextSchedule"
            :frequency="nextSchedule.frequency_information.frequency.value"
            :selected="Number(nextSchedule.frequency_information.frequency.value)"
            :frequencies="frequencies"
            :measure-id="measureId"
            :assignment-user-id="ccauId"
            :session-date="nextSchedule.due_date"
            :client-measure-id="nextSchedule.id"
            :unmodifiable="!nextSchedule.frequency_information.modifiable"
            :disabled="isDisabled(nextSchedule)"
          />
          <div class="due-date">
            <div class="title">
              {{ $t('nextDueDate') }}
            </div>
            <box-with-action
              :disabled="dueDateSaving === nextSchedule.id"
              :custom-actions="getCustomActions(nextSchedule)"
              @wasClicked="deleteDueDate(nextSchedule)"
            >
              <template slot="content">
                <measure-due-date
                  :date="nextSchedule.due_date"
                  :client-measure-id="nextSchedule.id"
                  :frequency="nextSchedule.frequency_information.frequency.value"
                  :measure-id="measureId"
                  :assigment-user-id="ccauId"
                  :read-only="readOnly"
                  :frequency-information="nextSchedule.frequency_information"
                />
              </template>
            </box-with-action>
          </div>
        </template>
      </div>
    </div>
    <div v-else-if="!measureData.clinical_administered">
      <div class="nex-due-dates-container">
        <measure-frequency
          :read-only="readOnly"
          :next-schedule="null"
          :frequencies="frequencies"
          :selected="Number(999)"
          :latest-completed-measure="latestCompletedMeasure"
          @changedFakeFrequency="changeNewDueDateFrequency"
        />
        <div
          v-if="!auditionMode"
          class="due-date"
        >
          <div class="title">
            {{ $t('nextDueDate') }}
          </div>
          <measure-due-date
            :date="'scheduleNew'"
            :measure-id="measureId"
            :frequency="fakeFrequencyValue"
            :assigment-user-id="ccauId"
            :read-only="readOnly"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MeasureDueDate from './MeasureDueDate'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { SchedulingType } from '@/mixins/scheduling/SchedulingType'
import ClinicalAdministeredButton from '@/components/clinician/patients/measures/ClinicalAdministeredButton'
import AdministeredMeasureCompletionData from '@/components/clinician/patients/measures/AdministeredMeasureCompletionData'
import BoxWithAction from '@/components/common/BoxWithAction'
import MeasureFrequency from '@/components/clinician/patients/measures/MeasureFrequency'

export default {
  name: 'MeasureDueDates',
  components: {
    AdministeredMeasureCompletionData,
    ClinicalAdministeredButton,
    MeasureDueDate,
    BoxWithAction,
    MeasureFrequency
  },
  mixins: [
    DateTimeHelper,
    SchedulingType
  ],
  props: [
    'nextSchedules',
    'ccauId',
    'measureId',
    'measureData',
    'frequency',
    'frequencies',
    'readOnly',
    'latestCompletedMeasure',
    'assignment'
  ],
  data () {
    return {
      fakeFrequencyValue: null
    }
  },
  computed: {
    ordererSchedules () {
      const schedules = Object.values(this.nextSchedules)
      return schedules.sort((pre, curr) => {
        return new Date(pre.due_date) - new Date(curr.due_date)
      })
    },
    isClinicalAdministeredUpdate () {
      return this.measureData.sessions_completed.length > 0
    },
    ...mapGetters({
      dueDateSaving: 'getDeletingScheduleMeasure',
      auditionMode: 'getAuditionMode',
      clinicianAssignments: 'getClinicianAssignments'
    })
  },
  methods: {
    getMeasureDataParams (schedule) {
      const params = { ...this.measureData }
      params.id = schedule.id
      return params
    },
    isDisabled (schedule) {
      // Change due to OWL-8262
      return this.getSchedulingTypeIsVisit(schedule.frequency_information) && schedule.frequency_information.frequency.value === 1
    },
    getCustomActions (schedule) {
      return this.getSchedulingTypeIsVisit(schedule.frequency_information)
        ? [{
            icon: 'fal fa-link',
            tooltip: {
              content: this.getLinkText(schedule),
              placement: 'right',
              classes: ['no-arrow']
            }
          }]
        : []
    },
    getLinkText (schedule) {
      return schedule.appointment ? this.$t('linkedToAVisit', { date: this.$toLocal(schedule.appointment.visit_date_time, 'MMM DD') }) : this.$t('notLinkedToAVisit')
    },
    deleteDueDate (schedule) {
      this.$emit('deleteSchedule', schedule)
    },
    changeNewDueDateFrequency (data) {
      if (data.option.value === 999) {
        this.fakeFrequencyValue = null
        return this.fakeFrequencyValue
      }

      this.fakeFrequencyValue = data.option.value
    }
  }
}
</script>
