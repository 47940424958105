<template>
  <div
    v-if="validComponentPropertiesValues()"
    class="detail"
  >
    <p class="title">
      Results Suggest:
    </p>
    <ul class="list-unstyled">
      <li
        v-for="(item, index) in resultSuggestion"
        :key="index"
      >
        {{ item.label }}<span v-if="item.data">: <strong>{{ getRsText(item.data) }}</strong></span>
      </li>
    </ul>
  </div>
</template>
<script>
import { Helpers } from '@/mixins/Helpers'

export default {
  name: 'MeasureAdditionalDetails',
  mixins: [Helpers],
  props: ['resultSuggestion', 'numDecimalDigits'],
  methods: {
    validComponentPropertiesValues () {
      // No result suggestion provided.
      if (!this.resultSuggestion || (this.resultSuggestion && !this.resultSuggestion.length)) {
        return false
      }

      // There is skipped questions.
      if (this.data && this.data.missing_answers) {
        return false
      }

      // everything good.
      return true
    },
    getRsText (text) {
      return isNaN(Number(text)) ? text : this.$numberWithDecimals(text, this.numDecimalDigits)
    }
  }
}
</script>
<style lang="scss" scoped>
.detail {
  ul {
    li {
      padding-left: 10px;
      font-size: 12px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 2;
      letter-spacing: normal;
      color: #0a3560;
    }
  }
}
body.pdf .detail {
  margin-top: 20px;
  // .title {
  //   margin: .5rem 0 0 0;
  // }
  p {
    margin-bottom: .6rem;
    font-weight: 600;
    font-size: 15px;
  }
  ul {
    li {
      font-size: 15px;
      padding-left: 0;
      line-height: 1;
      font-weight: 300;
    }
  }
}
</style>
