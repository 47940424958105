import { Helpers } from '@/mixins/Helpers'

export const ChartsHelper = {
  mixins: [Helpers],
  computed: {
    plotbandCutoff () {
      if (!this.data.series) return null
      const series = Object.values(this.data.series)
      const from = series[0] ? series[0].ymin : 0
      const to = series[0] ? series[0].ycutoff : null
      return to && series.every(s => s.ycutoff === to) && series.every(s => s.ymin === from) ? { from, to } : null
    },
    $yAxisMaxAndMin () {
      const max = Math.max.apply(Math, this.data.categories.map(v => v.ymax))
      const min = Math.min.apply(Math, this.data.categories.map(v => v.ymin))
      return {
        max,
        min
      }
    },
    $yAxisData () {
      const r = {
        plotBands: [],
        tickPositions: []
      }
      if (this.data.categories.length) {
        r.tickPositions.push(Number(this.$yAxisMaxAndMin.min))
        const plotBandsData = this.data.categories
        for (let i = 0; i < plotBandsData.length; i++) {
          const plotband = plotBandsData[i]
          r.plotBands.push({
            from: plotband.ymin,
            to: plotband.ymin,
            label: {
              text: plotband.label,
              align: 'right',
              x: -5,
              y: plotband.label.indexOf('<br>') > -1 ? -20 : -5
            }
          })
          r.tickPositions.push(Number(plotband.ymin))
        }
        if (this.$yAxisMaxAndMin.max) {
          r.tickPositions.push(Number(this.$yAxisMaxAndMin.max))
        }
      }
      if (this.plotbandCutoff) {
        r.plotBands.push({
          color: '#f5f8fb',
          from: parseFloat(this.plotbandCutoff.from),
          to: parseFloat(this.plotbandCutoff.to)
        })
      }
      return r
    },
    $xAxisData () {
      const r = {
        series: [],
        categories: []
      }
      const resultSuggestion = this.data.result_suggestions
      const series = this.data.series
      const container = this.getBarWidth(series.length)
      const scoresSerie = {
        name: this.$t('score'),
        pointWidth: container / 2,
        type: 'columnrange',
        dataLabels: {
          enabled: true,
          crop: false,
          overflow: 'none'
        },
        data: []
      }
      const maxValueSerie = {
        name: this.$t('scale'),
        pointWidth: container,
        type: 'columnrange',
        dataLabels: {
          enabled: false
        },
        data: []
      }
      const cutOffSerie = {
        name: this.$t('cutoff'),
        type: 'errorbar',
        whiskerLength: container + 36,
        data: []
      }
      for (let i = 0; i < series.length; i++) {
        const el = series[i]
        if (el.yvalue === null) {
          continue
        }
        r.categories.push(el.label)
        scoresSerie.data.push({
          low: Math.max(el.ymin, 0),
          high: this.numberWithDecimals(el.yvalue, this.numDecimalDigits)
        })
        maxValueSerie.data.push({
          // workarround to show a bar when score is === to the minimun
          low: parseFloat(el.ymin) === parseFloat(el.yvalue) ? el.ymin - 0.06 : el.ymin,
          high: this.numberWithDecimals(el.ymax, this.numDecimalDigits),
          className: 'is_scale',
          tooltipInfo: {
            low: el.ymin,
            cutoff: el.ycutoff,
            min: el.ymin,
            resultSuggestion: resultSuggestion,
            cutOffExplanation: el.ycutoff_explanation || {},
            value: this.numberWithDecimals(el.yvalue, this.numDecimalDigits),
            scale: this.numberWithDecimals(el.ymax, this.numDecimalDigits)
          }
        })
        const cutoff = el.ycutoff !== null ? this.numberWithDecimals(el.ycutoff, this.numDecimalDigits) : null
        cutOffSerie.data.push([cutoff, cutoff])
      }

      r.series.push(scoresSerie)
      r.series.push(maxValueSerie)
      r.series.push(cutOffSerie)
      // Push empty values to prevent chart overlaping with plotbands
      const emptyValuesForPadding = r.series[0].data.length > 6 ? 2 : r.series[0].data.length > 2 ? 1 : 0
      for (let i = 0; i < emptyValuesForPadding; i++) {
        r.series[0].data.push(null)
      }
      return r
    }
  },
  methods: {
    updateTitle () {
      this.chartOptions.title.text = this.chart.title
    },
    updateCaption () {
      this.chartOptions.caption = this.chart.caption
    },
    hexToRgb (hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          }
        : null
    },

    numberWithDecimals (number, numbOfDigits) {
      return this.$numberWithDecimals(number, numbOfDigits)
    },

    getDefaultChartConfig (type = '', chart = {}, exportingOptions = {}) {
      const legend = !chart.series || chart.series.length > 1
        ? {}
        : {
            symbolHeight: 0.001,
            symbolWidth: 0.001,
            symbolRadius: 0.001
          }
      const isFullWidthWithCaption = chart.horizontal

      return {
        chart: {
          backgroundColor: '#f1f5f8',
          plotBackgroundColor: chart.plotBackgroundColor || '',
          type: type,
          spacingTop: 25,
          spacingRight: 25,
          spacingLeft: 25,
          style: {
            overflow: 'visible',
            fontFamily: 'MuseoSans_300'
          }
        },
        lang: {
          contextButtonTitle: this.$t('contextButtonTitle'),
          noData: this.$t(chart.noDataMessage)
        },
        noData: chart.noData,
        colors: chart.colors || ['#8097ad', '#485d72', '#0a3560', '#b3c1d1', '#2068ac'],
        yAxis: chart.yAxis,
        xAxis: chart.xAxis,
        title: {
          text: chart.title || '',
          style: {
            color: chart.getColorNavy()
          }
        },
        subtitle: {
          text: chart.subtitle || '',
          style: {
            color: chart.getColorNavy()
          },
          x: isFullWidthWithCaption ? 125 : 0
        },
        tooltip: {
          enabled: false
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        legend: Object.assign(legend, chart.legend),
        plotOptions: {
          pie: {
            allowPointSelect: false,
            cursor: 'pointer',
            borderWidth: 5,
            dataLabels: {
              enabled: true,
              format: '{point.name}<br>{point.percentage:.1f}%',
              style: chart.getDefaultStyleObject()
            }
          },
          line: {
            connectNulls: true
          },
          series: {
            events: {
              legendItemClick: function () {
                return false
              }
            },
            states: {
              hover: {
                enabled: false
              },
              inactive: {
                enabled: false
              }
            },
            marker: {
              symbol: 'circle',
              enabled: true,
              radius: 5
            },
            maxPointWidth: 50
          }
        },
        exporting: {
          /**
           * We basically have two chart layouts:
           * 1. 2 square charts side by side per row (Demographics, or Inpatient Patient Status)
           * 2. Outcomes/Outpatient Patient Status - takes up full page width, 1 per row
           * The export options are different depending on which chart. For option 1, the caption appears below the chart.
           * For option 2, the caption appears to the right.
           */
          allowHTML: true,
          sourceWidth: exportingOptions.width ? exportingOptions.width : 750,
          sourceHeight: 500,
          scale: 1,
          buttons: {
            contextButton: {
              symbol: 'menuball'
            }
          },
          chartOptions: {
            subtitle: {
              x: isFullWidthWithCaption ? -10 : 0
            },
            chart: {
              height: 600,
              spacingBottom: exportingOptions.spacingBottom ? exportingOptions.spacingBottom : 100,
              spacingRight: exportingOptions ? exportingOptions.spacingRight : 0,
              events: {
                load: (e) => {
                  if (chart.caption) {
                    const isFullWidthWithCaption = chart.horizontal
                    const captionX = isFullWidthWithCaption ? 900 : 50
                    const captionY = isFullWidthWithCaption ? 70 : 450
                    const renderer = e.target.renderer
                    const captionText = chart.caption.replace(/\n/g, '<br/>')

                    renderer.text(captionText, captionX, captionY)
                      .css({
                        overflow: 'wrap',
                        overflowWrap: 'break-word',
                        color: chart.getColorNavy(),
                        fontFamily: 'MuseoSans_300',
                        fontSize: '14px',
                        zIndex: 100,
                        align: 'center',
                        width: isFullWidthWithCaption ? 250 : 550
                      })
                      .add()
                  }
                }
              }
            }
          }
        },
        credits: {
          enabled: false
        },
        series: chart.series || []
      }
    }
  }
}
