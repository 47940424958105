<template>
  <div
    ref="subMenuDots"
    class="dropdown sub-menu-dots"
    :class="{disabled: disabled}"
  >
    <i
      class="far fa-ellipsis-v"
      @click="toggle"
    />
    <div
      class="dropdown-menu"
      :class="{show: opened}"
    >
      <span
        v-if="showTitle"
        class="sub-menu-dots-title"
      >{{ title }}</span>
      <a
        v-for="(menuItem, index) in menuItems"
        :key="index"
        class="dropdown-item"
        @click="hideAndEmit(menuItem.action, menuItem.params)"
      >{{ menuItem.label }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubMenuDots',
  props: {
    menuItems: {
      type: Array,
      default: () => []
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Actions'
    }
  },
  data: function () {
    return {
      opened: false
    }
  },
  created () {
    document.addEventListener('click', this.handleClickOutside)
  },
  methods: {
    toggle () {
      if (!this.disabled) {
        this.opened = !this.opened
      }
    },
    close () {
      this.opened = false
    },
    hideAndEmit (action, params) {
      this.close()
      this.$emit(action, params)
    },
    handleClickOutside (event) {
      const subMenuDots = this.$refs.subMenuDots
      const target = event.target

      if (subMenuDots && subMenuDots !== target && !subMenuDots.contains(target)) {
        this.close()
      }
    }
  }
}
</script>
