import moment from 'moment'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
export const CalendarHelper = {
  mixins: [DateTimeHelper],
  data () {
    const date = this.date === 'scheduleNew' ? new Date() : this.date
    return {
      tooltip: {
        show: false,
        x: 200,
        y: 20,
        day: moment().format('D'),
        month: moment(date).format('M')
      }
    }
  },
  mounted () {
    this.$root.$on('calendarOpened', () => {
      document.body.removeEventListener('mouseover', this.showTooltip, false)
    })
  },
  computed: {
    measureFrequency () {
      if (this.selectedFrequency) {
        return parseInt(this.selectedFrequency.value)
      }
      return this.selectedMeasure ? this.selectedMeasure.default_frequency_reference.value : this.frequency
    },
    getTooltipStyles () {
      return {
        position: 'fixed',
        willChange: 'transform',
        top: this.tooltip.y + 'px',
        left: this.tooltip.x + 'px',
        opacity: 1
      }
    }
  },
  methods: {
    initPopoverAndDate () {
      this.$root.$emit('calendarOpened')
      document.body.addEventListener('mouseover', this.showTooltip, false)
      if (this.schedule && !this.schedule.due_date) {
        this.schedule.due_date = new Date()
        this.initialDate = new Date()
      }
    },
    changedMonth (month) {
      this.tooltip.month = moment(month).format('M')
    },
    getElementByDates (list) {
      const result = {}
      list.forEach(o => {
        const isTypeVisit = !!o.visit_date_time
        const date = isTypeVisit ? 'visit_date_time' : 'due_date'
        const index = moment(o[date]).format('MD')
        const value = !isTypeVisit ? o.measure_abbreviaton : `${this.$toLocal(o[date], 'hh:mm a')} visit`
        if (result[index]) {
          result[index].push(value)
        } else {
          result[index] = [value]
        }
      })
      return result
    },
    schedulingTypeIsVisit () {
      return (this.selectedScheduleType && this.selectedScheduleType.value === 'visit')
    },
    calcCalendarItemsByDate () {
      let measuresAndAppointments = this.measuresAssigned
      if (this.schedulingTypeIsVisit() || this.getSchedulingTypeIsVisit(this.frequencyInformation)) {
        measuresAndAppointments = measuresAndAppointments.concat(this.appointments)
      }
      this.calendarItemsByDate = this.getElementByDates(measuresAndAppointments)
    },
    getHighlightedDates () {
      let dates = this.measuresAssigned.filter(m => !!m.due_date && (m.completed === 0 || this.$isAfteroday(m.due_date))).map(o => {
        // Formatted to be compatible with Internet Explorer
        // RFC2822 or ISO 8601 formatted date.
        return new Date(this.$toLocal(o.due_date.replace(' ', 'T'), 'YYYY/MM/DD'))
      })

      if (this.schedulingTypeIsVisit() || this.getSchedulingTypeIsVisit(this.frequencyInformation)) {
        const appointments = this.appointments.filter(a => !!a.visit_date_time).map(a => {
          return new Date(this.$toLocal(a.visit_date_time.replace(' ', 'T'), 'YYYY/MM/DD'))
        })
        if (appointments) {
          dates = dates.concat(appointments)
        }
      }
      return {
        dates: dates,
        includeDisabled: true
      }
    },
    hideTooltip () {
      this.tooltip.show = false
    },
    showTooltip (e) {
      const target = e.target
      if (target && target.className && typeof target.className === 'string' && target.className.indexOf('highlighted') > -1 && target.className.indexOf('day') > -1) {
        const targetPosition = target.getBoundingClientRect()
        const x = (targetPosition) ? Number(targetPosition.left) + Number(targetPosition.width) : Number(e.x) + 32
        const y = (targetPosition) ? Number(targetPosition.top) : Number(e.y)
        this.tooltip.x = x + 10
        this.tooltip.y = y + 5
        this.tooltip.day = target.innerHTML
        this.tooltip.show = true
      } else {
        this.hideTooltip()
      }
    },
    getTooltipContent () {
      const index = this.tooltip.month + this.tooltip.day
      if (this.calendarItemsByDate[index]) {
        return this.calendarItemsByDate[index].join('<br />')
      }
      this.tooltip.show = false
      return ''
    }
  }
}
