<template>
  <button
    type="button"
    class="btn btn-outline-secondary clinical-administered-button btn-sm"
    @click="closeAndOpenClinicalAdministeredMeasure(measure)"
  >
    {{ takeButtonText }}
  </button>
</template>

<script>
import { mapMutations } from 'vuex'
import ScheduleTypes from '@/data/schedule-types'

export default {
  name: 'ClinicalAdministeredButton',
  props: ['measure', 'customAction', 'isUpdate', 'scheduleNew'], // eslint-disable-line vue/require-prop-types
  data () {
    return {
      scheduleTypes: ScheduleTypes
    }
  },
  computed: {
    takeButtonText () {
      const prefix = this.isUpdate ? this.$t('update') : this.$t('take')
      return `${prefix} ${this.measure.abbreviation}`
    }
  },
  methods: {
    ...mapMutations({
      resetMeasuresAssigned: 'measures/RESET_MEASURES_ASSIGNED_DURING_SESSION'
    }),
    async closeAndOpenClinicalAdministeredMeasure () {
      this.resetMeasuresAssigned()
      if (this.customAction && typeof this.customAction === 'function') {
        return this.customAction()
      }
      const measureId = this.scheduleNew ? await this.scheduleNewAndGetId() : this.measure.id
      const params = { ccauId: this.$route.params.ccauId, ccaId: this.$route.params.ccaId }
      this.$router.push({ name: 'ClinicianPatientMeasures', params, query: { clinicalAdministeredMeasure: measureId } })
    },
    scheduleNewAndGetId () {
      const newSchedule = {
        client_clinic_assignment_user_id: this.measure.client_clinic_assignment_user_id,
        measure_id: this.measure.measure_id,
        due_date: new Date(),
        frequency: this.measure.frequency.value,
        ignore_scheduled: true,
        schedule_type: 'calendar'
      }
      if (this.measure.is_pre_populate) {
        newSchedule.pre_populate_with = this.measure.id
      }
      return new Promise((resolve) => {
        this.$store.dispatch('SCHEDULE_MEASURE', newSchedule).then((response) => {
          resolve(response.id)
        }).catch(() => {
          resolve(null)
        })
      })
    }
  }
}
</script>
