export const SchedulingType = {
  methods: {
    getSchedulingType (frequencyInformation) {
      return frequencyInformation ? parseInt(frequencyInformation.frequency.type_id) : 1
    },
    getSchedulingTypeIsVisit (frequencyInformation) {
      return this.getSchedulingType(frequencyInformation) === 2
    }
  }
}
