<template>
  <div class="chart-header">
    <sub-menu-dots
      v-if="showMenu"
      :menu-items="menuItems"
      :show-title="false"
      @showAll="showAll"
      @hideAll="hideAll"
    />

    <MoreInfoTooltip
      v-if="moreInfo"
      :data="moreInfo"
      :close-button="true"
    />
  </div>
</template>

<style>

</style>
<script>
import SubMenuDots from '@/components/common/SubMenuDots'
import MoreInfoTooltip from '@/components/common/MoreInfoTooltip'

export default {
  name: 'MeasureChartHeader',
  components: {
    SubMenuDots,
    MoreInfoTooltip
  },
  props: ['showAllButton', 'hideallButton', 'moreInfo', 'showMenu'],
  data () {
    return {
      show: false
    }
  },
  computed: {
    menuItems () {
      const menuItems = []
      if (this.showAllButton) {
        menuItems.push({ action: 'showAll', label: `${this.$t('show')} ${this.$t('all')}` })
      }
      if (this.hideallButton) {
        menuItems.push({ action: 'hideAll', label: `${this.$t('hide')} ${this.$t('all')}` })
      }
      return menuItems
    },
    moreInfoTooltip () {
      return {
        placement: 'bottom-end',
        classes: ['more-info-tooltip'],
        trigger: 'click',
        content: this.moreInfo
      }
    }
  },
  created () {
  },
  methods: {
    showAll () {
      this.$emit('setShowAll', true)
    },
    hideAll () {
      this.$emit('setShowAll', false)
    }
  }
}
</script>
<style lang="scss">
.more-info-tooltip {
  border-radius: 0;
  max-width: 400px;
  font-size: 16px;
  .popover-inner.tooltip-inner {
    text-align: left;
    padding: 0.5rem 0;
    max-width: 400px;
    min-width: 150px;
    border-radius: 0;
    .popover-content {
      padding-left: 24px;
      padding-right: 24px;
      max-height: 260px;
      overflow-y: scroll;
    }
  }
  .popover-close {
    display: flex;
    justify-content: space-between;
    padding-right: 24px;
    padding-left: 24px;
    p {
      font-weight: 700;
      margin-bottom: 0;
    }
    i {
      padding: 2px 5px;
      cursor: pointer;
    }
  }
}
</style>
