<template>
  <span
    v-tooltip="tooltipData"
    class="badge badge-pill badge-discontinued"
  >
    {{ $t('discontinued') }}
  </span>
</template>

<script>
import { DateTimeHelper } from '@/mixins/DateTimeHelper'

export default {
  name: 'MeasureDiscontinuedBadge',
  mixins: [DateTimeHelper],
  props: ['discontinuedLastChange', 'replacingMeasure'],
  computed: {
    replacingMeasureText () {
      return this.replacingMeasure ? this.$t('measureDiscontinuedTooltipReplacedWith', { measure: this.replacingMeasure.abbreviation }) : ''
    },
    discontinuedDate () {
      return this.discontinuedLastChange ? this.$toLocal(this.discontinuedLastChange, 'MMM DD, YYYY') : ''
    },
    tooltipData () {
      const text = this.$t('measureDiscontinuedTooltip', { date: this.discontinuedDate }) + this.replacingMeasureText
      return {
        content: text,
        placement: 'bottom',
        classes: ['discontinued-tooltip'],
        delay: {
          show: 300,
          hide: 300
        }
      }
    }
  }
}
</script>
