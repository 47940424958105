<template>
  <chart
    :options="chartOptions"
    :callback="adjustLabelRotation"
  />
</template>

<script>
import { Chart } from 'highcharts-vue'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { ChartsHelper } from '@/mixins/ChartsHelper'

export default {
  name: 'BarChart',
  components: {
    Chart
  },
  mixins: [DateTimeHelper, ChartsHelper],
  props: ['data', 'colors', 'numDecimalDigits'],
  data: function () {
    const component = this
    const data = this.data
    const toLocal = this.$toLocal
    return {
      lastTooltipInfo: {},
      dataLabels: [],
      chartOptions: {
        chart: {
          type: 'column',
          spacingTop: 20,
          spacingRight: component.data.categories_as_y_labels ? 0 : 20
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        colors: this.colors,
        xAxis: [{
          categories: [],
          tickLength: 0,
          labels: {
            formatter: function () {
              return typeof this.value === 'string' ? this.value : ''
            },
            style: {
              'font-family': 'MuseoSans',
              'font-size': '13px',
              'font-weight': '500',
              'font-style': 'normal',
              'font-stretch': 'normal',
              'line-height': '1.0',
              'letter-spacing': 'normal',
              'text-transform': 'uppercase',
              'text-align': 'center',
              color: '#0a3560'
            }
          }
        }, {
          lineColor: 'transparent',
          title: {
            text: '',
            style: {
              'font-family': 'MuseoSans',
              'font-size': '15px',
              'font-weight': '500',
              'font-style': 'normal',
              'font-stretch': 'normal',
              'line-height': '1.0',
              'letter-spacing': 'normal',
              'text-align': 'center',
              color: '#0a3560'
            }
          }
        }],
        yAxis: [{
          labels: {
            useHTML: true,
            formatter: function () {
              return `<div class="tw-w-8 tw-text-right tw-uppercase">${this.value}</div>`
            }
          },
          title: {
            text: ''
          }
        }, {
          categories: [],
          opposite: true,
          title: {
            text: ''
          }
        }],
        tooltip: {
          shared: false,
          formatter: function () {
            let tooltipInfo = this.point.options.tooltipInfo
            let tooltipMarkup = ''
            const completedDate = toLocal(data.completion_date, 'ddd MMM DD, YYYY h:mm A')

            const rgb = component.hexToRgb(this.point.color)

            // default
            if (!tooltipInfo) {
              tooltipInfo = component.lastTooltipInfo
            } else {
              component.lastTooltipInfo = this.point.options.tooltipInfo
            }

            // label
            if (tooltipInfo.label) {
              tooltipMarkup += `<b>${tooltipInfo.label}</b></b><br/>`
            }

            tooltipMarkup += `<span style="color:${this.point.color}">\u25CF</span> ${component.$t('score')}: <b>${tooltipInfo.value}</b><br/>`

            // scale
            if (tooltipInfo.scale) {
              const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${'0.3'})`
              const min = tooltipInfo.min ? tooltipInfo.min : '0'
              tooltipMarkup += `<span style="color:${rgba}">\u25CF</span> ${component.$t('range')}: <b>${min} - ${tooltipInfo.scale}</b><br/>`
            }

            // cutoff
            if (tooltipInfo.cutoff) {
              const label = tooltipInfo.cutOffExplanation.label ? tooltipInfo.cutOffExplanation.label : component.$t('cutoff')
              const value = tooltipInfo.cutOffExplanation.text_value ? tooltipInfo.cutOffExplanation.text_value : tooltipInfo.cutoff
              tooltipMarkup += `<span style="color:${'#ed616a'}">\u25CF</span> ${label}: <b>${value}</b><br/>`
            }

            // result suggestion.
            if (tooltipInfo.resultSuggestion && tooltipInfo.resultSuggestion.length) {
              for (let i = 0; i < tooltipInfo.resultSuggestion.length; i++) {
                const rs = tooltipInfo.resultSuggestion[i]
                const dataValue = isNaN(Number(rs.data)) ? rs.data : component.numberWithDecimals(rs.data, component.numDecimalDigits)
                const dataText = rs.data ? `${rs.label}: ${dataValue}` : rs.label
                tooltipMarkup += `<br> <span style="color:transparent">\u25CF</span> ${dataText}`
              }
            }
            tooltipMarkup += `<br> <span style="color:transparent">\u25CF</span> ${completedDate}`

            return tooltipMarkup
          }
        },
        plotOptions: {
          series: {
            minPointLength: 3,
            colorByPoint: true,
            dataLabels: {
              style: {
                textOutline: 0
              },
              color: '#03204a'
            }
          },
          errorbar: {
            whiskerColor: '#ed616a',
            whiskerWidth: '2'
          },
          columnrange: {
            stickyTracking: false,
            stacking: 'normal',
            dataLabels: {
              enabled: true,
              inside: false,
              formatter: function (point) {
                const isNegativeValue = this.point.low === 0 && this.point.high < 0
                if (point.verticalAlign === 'top') {
                  return isNegativeValue ? this.point.high : null
                }

                return isNegativeValue ? null : this.point.high
              }
            }
          },
          column: {
            grouping: false,
            shadow: false,
            borderWidth: 0
          }
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: []
      }
    }
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  },
  created () {
    if (this.data.categories_as_y_labels) {
      this.chartOptions.yAxis[1].categories = this.data.categories.map(c => c.label)
      this.chartOptions.yAxis[1].tickPositions = this.$yAxisData.tickPositions
    } else {
      this.chartOptions.yAxis[0].plotBands = this.$yAxisData.plotBands
    }
    this.chartOptions.yAxis[0].tickPositions = this.$yAxisData.tickPositions
    this.chartOptions.yAxis[1].title.text = this.data.y_right_label ? this.data.y_right_label : '&nbsp;' // hack to always force a space so margins align
    this.chartOptions.yAxis[1].title.useHTML = true
    this.chartOptions.xAxis[0].categories = this.$xAxisData.categories
    this.chartOptions.xAxis[1].title.text = this.$toLocal(this.data.completion_date, 'MMM DD, YYYY')
    this.chartOptions.series = this.$xAxisData.series
    if (this.currentRouteName === 'ClinicianPatientMeasurePDF' || this.currentRouteName === 'ClinicianPatientPDF') {
      this.chartOptions.plotOptions.series.animation = false
      this.chartOptions.chart.spacingLeft = 38
      this.chartOptions.chart.spacingRight = 52
    }
  },
  methods: {
    getBarWidth (seriesLength) {
      let container = ((window.outerWidth - 670) / seriesLength) - 30
      if (container > 100) {
        container = 100
      } else if (container < 25) {
        container = 25
      }
      return container
    },
    adjustLabelRotation (chart) {
      if (!chart.series.length) { return }
      try {
        const columnW = chart.series[0].points[0].shapeArgs.width
        const points = chart.series[0].points

        const rotate = points.some(point => point.dataLabel && (columnW * 0.7) < point.dataLabel.width)
        const options = { ...this.chartOptions }
        if (rotate) {
          options.xAxis[0].labels.style['font-size'] = '8px'
        }
        this.chartOptions = options
      } catch (error) {
        // console.log(error) // eslint-disable-line no-console
      }
    }
  }
}
</script>
<style lang="scss">
.highcharts-series-group .highcharts-point.is_scale {
  opacity: 0.3;
}
</style>
