<template>
  <!-- PDF template -->
  <div
    v-if="currentRouteName === 'ClinicianPatientMeasurePDF' || currentRouteName === 'ClinicianPatientPDF'"
    class="measure-list-pdf"
  >
    <div
      v-if="isProgressChart === 'false'"
      class="measure-info-header"
    >
      <div
        v-if="data"
        class="measure-info-abbr abbreviation"
      >
        {{ data.abbreviation }} on {{ titleDate }}
      </div>
    </div>
    <div
      v-if="isProgressChart === 'true'"
      class="measure-info-header"
    >
      <div
        v-if="data"
        class="measure-info-abbr abbreviation"
      >
        {{ data.abbreviation }} Progress <span class="measure-info-name">{{ data.name }}</span>
      </div>
    </div>
    <div
      class="measure-chart-container"
    >
      <div
        v-if="showChartSkippedMessage"
        class="missing-answers-div"
      >
        <p>
          <strong>{{ $t('measuresCantBeScored') }}</strong>
          {{ $t('questionRequiredToScore') }}
        </p>
      </div>
      <measureChartTabs
        v-else
        :data="data"
        :next-due-date="date"
        :chart-index-init="chartIndexInit"
      />
    </div>
  </div>

  <!-- PDF template END -->

  <div
    v-else
    class="measure-list-item"
    :class="{'read-only': readOnly, 'collapsed': !open}"
  >
    <div class="measure-info">
      <div class="measure-info-header">
        <div class="measure-info-abbr">
          {{ data.abbreviation }}
          <MeasureStatusIcon
            v-if="skippedQuestionIconVisible"
            :session="{status: 3, client_measure_status: 0}"
          />
        </div>
        <div class="measure-info-name">
          {{ data.name }}
        </div>
        <sub-menu-dots
          v-if="!audition"
          :menu-items="menuItems"
          @toggleHideGraph="toggleHideGraph"
          @deleteMeasure="triggerRemoveMeasure(data.id)"
          @customMeasure="customMeasure"
          @viewDetail="viewDetail"
          @viewSessionReport="viewSessionReport"
          @goToProgressGraph="goToProgressGraph"
        />
        <delete-dialog
          v-if="deleteDialog"
          :title="deleteDialogTitle"
          :body="deleteDialogBody"
          :cancel-btn="$t('cancel')"
          :confirm-btn="confirmBtn"
          @onDelete="deletionConfirmed"
          @onCancel="onCancel"
        />
      </div>
      <div
        v-if="open"
        class="measure-info-details"
      >
        <measure-list-item-detail-info
          :data="data"
          :result-suggestion="resultSuggestion"
          :num-decimal-digits="numDigits"
          :assignment="assignment"
          :date="date"
          @editResponses="openEditMeasure(data)"
          @deleteSchedule="deleteSchedule"
        />
      </div>
    </div>
    <div
      v-if="open"
      class="measure-chart-container"
    >
      <div
        v-if="showChartSkippedMessage"
        class="missing-answers-div"
      >
        <p>
          <strong>{{ $t('measuresCantBeScored') }}</strong>
          {{ $t('questionRequiredToScore') }}
          <a
            v-if="!audition"
            class="pointer"
            @click="openEditMeasure(data)"
          >
            {{ $t('clickToEditResponses') }}
          </a>
        </p>
      </div>
      <measureChartTabs
        v-else
        :data="data"
        :next-due-date="date"
        :chart-index-init="chartIndexInit"
        @editResponses="openEditMeasure(data)"
        @viewSessionReport="viewSessionReport"
        @setChartSelected="(s) => chartSelected = s"
        @setChartIndexSelected="(s) => chartItemSelected = s"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import { PatientHelper } from '@/mixins/PatientHelper'
import MeasureChartTabs from '@/components/clinician/patients/measures/charts/MeasureChartTabs'
import MeasureListItemDetailInfo from '@/components/clinician/patients/measures/MeasureListItemDetailInfo'
import MeasureStatusIcon from '@/components/common/MeasureStatusIcon'
import SubMenuDots from '@/components/common/SubMenuDots'
import DeleteDialog from '@/components/common/DeleteDialog'
import { DELETE_SCHEDULED_MEASURE } from '@/store/modules/clinicians/constants.js'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'

export default {
  name: 'MeasureListItem',
  components: {
    MeasureListItemDetailInfo,
    MeasureChartTabs,
    SubMenuDots,
    DeleteDialog,
    MeasureStatusIcon
  },
  mixins: [Helpers, PatientHelper, DateTimeHelper],
  props: ['data', 'readOnly', 'index', 'compactSubMenu', 'missingAnswers', 'audition', 'chartIndexInit', 'isProgressChart'],
  data () {
    return {
      scheduleToDelete: null,
      chartItemSelected: null,
      chartSelected: {},
      isDeleteMeasure: false,
      deleteDialog: false
    }
  },
  computed: {
    ...mapGetters({
      teamId: 'getTeamId',
      assignment: 'getActiveAssignment',
      uiSettings: 'getUiSettings'
    }),
    numDigits () {
      return this.chartSelected && this.chartSelected.num_decimal_digits ? this.chartSelected.num_decimal_digits : 2
    },
    // eslint-disable-next-line vue/return-in-computed-property
    resultSuggestion () {
      if (this.chartSelected) {
        if (this.showChartSkippedMessage) {
          return null
        }
        if (this.chartSelected.result_suggestions) {
          return this.chartSelected.result_suggestions
        }
        if (this.chartSelected.sessions_progress_results) {
          const found = this.chartSelected.sessions_progress_results.find(r => r.result_suggestions && r.result_suggestions.length)
          return found ? found.result_suggestions : null
        }
        return null
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    titleDate () {
      return this.$toLocal(this.data.sessions_completed[0].measure_date_completed, 'MMM DD, YYYY')
    },
    currentRouteName () {
      return this.$route.name
    },
    // eslint-disable-next-line vue/return-in-computed-property
    showChartSkippedMessage () {
      let show = false
      if (this.chartSelected) {
        if ((this.chartSelected.unanswered_questions && this.data.completedDataWithScores < 1) ||
        (this.chartSelected.unanswered_questions && this.isDetailOrReport)) {
          show = true
        }

        return show
      }
    },
    justOneMeasureComplete () {
      return this.data.sessions_completed && this.data.sessions_completed.length === 1
    },
    moreThanOneMeasureComplete () {
      return this.data.sessions_completed && this.data.sessions_completed.length > 1
    },
    skippedQuestionIconVisible () {
      if (this.justOneMeasureComplete) {
        return this.data.sessions_completed[0].unanswered_questions
      }

      if (this.isDetailOrReport) {
        return this.chartSelected.unanswered_questions
      }

      if (this.moreThanOneMeasureComplete) {
        const hasUnansweredQuestions = this.data.sessions_completed.find(sessionCompleted => sessionCompleted.unanswered_questions)
        return (hasUnansweredQuestions)
      }

      return false
    },
    isDetailOrReport () {
      return this.$route.name === 'SessionDetail' ||
      this.$route.name === 'AuditionMeasureReport' ||
      this.currentRouteName === 'ClinicianPatientMeasurePDF' ||
      this.currentRouteName === 'ClinicianPatientPDF'
    },
    open () {
      let r
      try {
        r = this.uiSettings.patients[this.data.client_clinic_assignment_user_id].measureOverview[this.data.id].open
      } catch (error) {
        r = true
      }
      return r === undefined || !!r
    },
    date () {
      const dueDate = this.getMeasureDueDate()
      return dueDate !== false ? dueDate : this.$t('none')
    },
    sessionDate () {
      return (this.data.sessions_completed.length) ? this.data.sessions_completed[0].due_date : this.getMeasureDueDate()
    },
    menuItems () {
      const menuItems = []
      if (this.compactSubMenu) {
        menuItems.push({ action: 'goToProgressGraph', label: this.$t('goToProgressGraph') })
      } else {
        menuItems.push({ action: 'toggleHideGraph', label: `${this.open ? this.$t('hide') : this.$t('show')} ${this.$t('graph')}` })
        if (this.data.sessions_completed && this.$route.name !== 'SessionDetail') {
          menuItems.push({ action: 'viewSessionReport', label: this.$t('viewSessionReport') })
        }
        if (this.data.customizable && !this.readOnly) {
          menuItems.push({ action: 'customMeasure', label: this.$t('editQuestions') })
        }
        if (this.data.latestCompletedDate === Number.NEGATIVE_INFINITY) {
          menuItems.push({ action: 'deleteMeasure', label: 'Delete Measure' })
        }
      }
      menuItems.push({ action: 'viewDetail', label: this.$t('measureOverview') })

      return menuItems
    },
    /**
     * Compute the value of Delete Dialog Title,
     * When in context of Clicking "Delete option" of 3 dots menu OR clicking X for a
     * scheduled due date and has not previous completed sessions of the same instance of the measure return the delete title
     * Else, if clicking the X button for a Scheduled measure for a measure with previous results, it shows the
     * Disabled scheduling title.
     */
    deleteDialogTitle () {
      // we also show delete title when we have 1 next schedule and no completed measure data
      if (this.isDeleteMeasure || (this.data.next_schedules.length === 1 && this.data.sessions_completed.length <= 0)) {
        return this.$t('areYouSureToDeleteItem', { item: this.$t('thisMeasure') })
      } else if (this.data.next_schedules.length === 1 && this.data.sessions_completed.length > 0) {
        return this.getDisableMeasureSchedulingDialogTitle()
      }

      return ''
    },
    /**
     * Compute the value of Delete Dialog Body,
     * When in context of Clicking "Delete option" of 3 dots menu OR clicking X for a
     * scheduled due date and has not previous completed sessions of the same instance of the measure return the delete Body
     * Else, if clicking the X button for a Scheduled measure for a measure with previous results, it shows the
     * Disabled scheduling title.
     * When the measure has been already started (not completed), the Delete and Disable Scheduling messages
     * vary to reflect that by taking action, the Responses of the Respondent Will be deleted.
     */
    /* eslint-disable vue/return-in-computed-property */
    deleteDialogBody () {
      const inUse = this.getMeasureListItemIsInUse()
      // When Deleting from 3 menu dots check all assigned measures has not been started. (Delete flow from 3 dots).
      if (this.isDeleteMeasure) {
        return inUse ? this.$t('deleteStartedMeasureDialogBody') : this.$t('deleteMeasureBody', { item: this.$t('thisMeasure') })
      }

      // The following flow is only executed when Clicking on X button and there is only
      // One instance of the same measure assigned
      // When deleting from X icon and measure has no previous sessions were completed (Delete flow from x)
      const hasOneScheduled = this.data && this.data.next_schedules && this.data.next_schedules.length === 1
      const hasSessionsCompleted = this.data && this.data.sessions_completed && this.data.sessions_completed.length > 0
      if (hasOneScheduled && !hasSessionsCompleted) {
        return inUse ? this.$t('deleteStartedMeasureDialogBody') : this.$t('deleteDueDateBody')
      }

      // When Deleting from x icon and measure has previous sessions completed (Disable Scheduling)
      if (hasOneScheduled && hasSessionsCompleted) {
        return inUse ? this.$t('disableStartedSchedulingDialogBody') : this.$t('disableSchedulingBody')
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    confirmBtn () {
      if (this.data) {
        return this.data.next_schedules.length === 1 && this.data.sessions_completed.length > 0 ? this.$t('yesDisable') : this.$t('yesDelete')
      }
    }
  },
  created () {
    if (this.data) {
      if (this.isDetailOrReport) {
        if (this.currentRouteName === 'ClinicianPatientPDF' || this.currentRouteName === 'ClinicianPatientMeasurePDF') {
          try {
            this.chartSelected = this.data.chart[0].progress_chart[0]
          } catch (error) {}
        } else {
          try {
            this.chartSelected = this.data.chart[0].session_chart[0]
          } catch (error) {}
        }
      }
    }
  },
  methods: {
    openEditMeasure (data) {
      this.$emit('openEditMeasure', data)
    },
    viewSessionReport () {
      const params = {
        ccaId: this.$getEncodedId(this.assignment.assignment_id),
        ccauId: this.$getEncodedId(this.assignment.ccauId),
        measureId: this.$getEncodedId(this.data.sessions_completed[0].id)
      }
      this.$router.push({ name: 'SessionDetail', params: params })
    },
    customMeasure () {
      this.$store.dispatch('SET_CUSTOM_MEASURE', this.data.next_schedules[0].id)
    },
    getMeasureDueDate () {
      if (this.data) {
        return this.data.next_schedules && this.data.next_schedules.length ? this.data.next_schedules[0].due_date : false
      }
    },
    deletionConfirmed () {
      this.isDeleteMeasure = this.data.next_schedules.length !== 1 && this.data.sessions_completed.length <= 0
      this.isDeleteMeasure ? this.removeMeasure() : this.data.next_schedules.length === 1 ? this.disableSchedulingConfirmed() : this.onDelete()
    },
    removeMeasure () {
      const params = {
        clientMeasureId: this.scheduleToDelete
      }
      const message = this.$t('measureDeleted')
      const dischargedMessage = this.$t('dischargeFinalizedMessage')
      const loader = this.$loading.show()
      this.onCancel()
      this.$store.dispatch(DELETE_SCHEDULED_MEASURE, params).then(() => {
        this.$refreshActiveAssignmentData()
        this.getRespondentLatestSession()
        this.dischargeIfPreDischarged(dischargedMessage)
        this.$toast.success({ message: message })
      }).finally(() => {
        loader.hide()
      })
    },
    disableSchedulingConfirmed () {
      this.$emit('measureDisabled')
      const dl = this.$t('measureDisabled')
      const dischargedMessage = this.$t('dischargeFinalizedMessage')
      const params = {
        clientMeasureId: this.scheduleToDelete,
        apply_to_all_measures_in_session: false,
        session_date_time: this.getMeasureDueDate(),
        client_clinic_assignment_user_id: this.assignment.ccauId
      }
      const loader = this.$loading.show()
      this.onCancel()
      this.$store.dispatch(DELETE_SCHEDULED_MEASURE, params).then(() => {
        this.$refreshActiveAssignmentData()
        this.getRespondentLatestSession()
        this.dischargeIfPreDischarged(dischargedMessage)
        this.$toast.success({ message: dl })
      }).finally(() => {
        loader.hide()
      })
    },
    onDelete () {
      const dl = this.$t('dueDateRemoved')
      this.deleteDialog = false
      this.isDeleteMeasure = false
      this.$store.dispatch(DELETE_SCHEDULED_MEASURE, { clientMeasureId: this.scheduleToDelete }).then(() => {
        this.$refreshActiveAssignmentData()
        this.getRespondentLatestSession()
        this.$emit('onDelete')
        this.$toast.success({ message: dl })
      })
    },
    onCancel () {
      this.deleteDialog = false
      this.scheduleToDelete = null
      this.isDeleteMeasure = false
    },
    toggleHideGraph () {
      this.$store.dispatch('TOGGLE_MEASURE_LIST_ITEM_OPEN', this.data).then(() => {
        this.$store.dispatch('UPDATE_UI_SETTINGS', this.uiSettings)
      })
    },
    viewDetail () {
      this.$emit('viewDetail', this.data)
    },
    goToProgressGraph () {
      const params = { ccaId: this.$getEncodedId(this.assignment.assignment_id), ccauId: this.$getEncodedId(this.assignment.ccauId) }
      const query = {}
      if (this.chartItemSelected) {
        query.chartIndex = this.chartItemSelected
        query.measureId = this.data.id
      }
      this.$router.push({ name: 'ClinicianPatientMeasures', params, query })
    },
    deleteSchedule (schedule) {
      this.scheduleToDelete = schedule.id
      if (this.data.next_schedules.length > 1) {
        return this.deletionConfirmed()
      }
      this.deleteDialog = true
    },
    triggerRemoveMeasure (id) {
      this.deleteDialog = true
      this.isDeleteMeasure = true
      this.scheduleToDelete = id
    },
    /**
     * Get Disable Measure Scheduling Data Dialog Title String
     */
    getDisableMeasureSchedulingDialogTitle () {
      const inUse = this.getMeasureListItemIsInUse()
      return inUse ? this.$t('areYouSureToDisableSchedulingStarted') : this.$t('areYouSureToDisableScheduling')
    },
    /**
     * Check instance of the same measure assigned and determine if the measure
     * is in use, it works when using 3 dots menu, because when more than one measure assigned and not completed
     * We need to evaluate all of them.
     * In the case that the user use the X button, this method is only executed when there is Only ONE (1)
     * scheduling in the future, in cases that the user has more, this will never run from that flow.
     *
     * @returns {boolean}
     */
    getMeasureListItemIsInUse () {
      const scheduleLength = this.data && this.data.next_schedules && this.data.next_schedules.length
      let measureInstanceInUse = false
      if (scheduleLength) {
        for (let i = 0; i < scheduleLength; i++) {
          if (this.data.next_schedules[i].in_use) {
            measureInstanceInUse = true
            break
          }
        }
      }
      return measureInstanceInUse
    }
  }
}
</script>
<style lang="scss" scoped>
.for-pdf .measure-info-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  text-align: left;
}
.for-pdf .measure-info-details {
  text-align: left;
}
.abbreviation {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #0a3560;
  text-align: left;
}
.measure-info-name {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #0a3560;
}
.measure-list-pdf {
  margin-top: 40px;
}
.measure-list-pdf .measure-info-details {
  text-align: left;
}
.measure-list-pdf .missing-answers-div {
  text-align: center;
  margin: 32px 0;
}
</style>
