<template>
  <chart
    v-if="currentRouteName === 'ClinicianPatientMeasurePDF' || currentRouteName === 'ClinicianPatientPDF'"
    ref="progressChart"
    :options="chartOptionsPDF"
    :update-args="updateArgs"
    :deep-copy-on-update="true"
  />
  <chart
    v-else
    ref="progressChart"
    :options="chartOptions"
    :update-args="updateArgs"
    :deep-copy-on-update="true"
  />
</template>

<script>
import { Chart } from 'highcharts-vue'
import moment from 'moment'
import { Helpers } from '@/mixins/Helpers'
import { ChartsHelper } from '@/mixins/ChartsHelper'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { mapGetters } from 'vuex'

export default {
  name: 'ProgressBarChart',
  components: {
    Chart
  },
  mixins: [Helpers, ChartsHelper, DateTimeHelper],
  props: ['data', 'colors', 'scale', 'numDecimalDigits', 'chartIndex', 'isMultiSeriesChart'],
  data () {
    return {
      offset: 4,
      chartOptions: {},
      chartOptionsPDF: {},
      updateArgs: [true, true, { duration: 1000 }],
      lastPoint: null
    }
  },
  computed: {
    ...mapGetters({
      currentBrowser: 'getCurrentBrowser'
    }),
    earliest () {
      const measuresToShow = this.data.sessions_progress_results.filter(item => item.unanswered_questions === false)
      return this.getEarliestFromArray(measuresToShow, 'measure_date_completed')
    },
    latest () {
      const measuresToShow = this.data.sessions_progress_results.filter(item => item.unanswered_questions === false)
      return this.getLatestFromArray(measuresToShow, 'measure_date_completed')
    },
    startDate () {
      let startDate
      if (this.showScroll) {
        startDate = moment(this.$toLocal(this.latest.measure_date_completed)).add(-1 * this.offset, this.scale).startOf('day').valueOf()
      } else {
        startDate = moment(this.$toLocal(this.earliest.measure_date_completed))
        if (startDate.weekday() === 0) {
        // Fix particular case for measures completed on Sundays
          startDate.add(-1, 'day')
        }
        startDate = startDate.startOf('day').valueOf()
      }
      return startDate
    },
    endDate () {
      let endDate
      if (this.showScroll) {
        endDate = moment(this.$toLocal(this.latest.measure_date_completed)).add(1, this.scale).endOf(this.scale).valueOf()
      } else {
        endDate = moment(this.$toLocal(this.earliest.measure_date_completed)).add((this.offset + 1), this.scale)
        if (endDate.weekday() === 0) {
        // Fix particular case for measures completed on Sundays
          endDate.add(-1, 'day').endOf(this.scale)
        }
        endDate = endDate.valueOf()
      }
      return endDate
    },
    rangeEndDate () {
      return moment(this.$toLocal(this.earliest.measure_date_completed)).add(this.offset, this.scale).endOf(this.scale).valueOf()
    },
    showScroll () {
      if (this.currentRouteName === 'ClinicianPatientMeasurePDF' || this.currentRouteName === 'ClinicianPatientPDF') {
        return false
      } else {
        return this.rangeEndDate < moment(this.$toLocal(this.latest.measure_date_completed)).valueOf()
      }
    },
    series () {
      const series = this.data.series.map((serie, index) => {
        return {
          name: serie.label.capitalize(),
          data: this.getMeasureSeriesData(serie, index),
          visible: true
        }
      }).filter(s => s.data && s.data.length)
      return series
    },
    currentRouteName () {
      return this.$route.name
    }
  },
  created () {
    if (this.currentRouteName === 'ClinicianPatientMeasurePDF' || this.currentRouteName === 'ClinicianPatientPDF') {
      this.setChartOptionsPDF()
    } else {
      this.setChartOptions()
    }
  },
  mounted () {
    this.legendItemClick()
  },
  methods: {
    initSeries (evt) {
      for (let j = 0; j < evt.target.series.length; j++) {
        const serie = evt.target.series[j]
        for (let i = 0; i < serie.data.length; i++) {
          const point = serie.data[i]
          point.setState('normal')
          point.dataLabel.css({ fontSize: '0px' })
        }
      }
    },
    showLastPoint () {
      if (!this.lastPoint) {
        return
      }
      this.lastPoint.setState('hover')
      this.lastPoint.dataLabel.css({ fontSize: '11px' })
    },
    showLastPointMultiForPDF () {
      if (!this.lastPoint) {
        return
      }
      this.lastPoint.dataLabel.css({ display: 'none' })
    },
    hideLastPoint (index = null) {
      if (this.lastPoint === null || index === this.lastPoint.index) {
        return
      }
      this.lastPoint.setState('normal')
      this.lastPoint.dataLabel.css({ fontSize: '0px' })
      this.lastPoint = null
    },
    setChartOptions () {
      const component = this
      const labelFormat = this.getLabelFormat()
      const paddingTop = this.currentBrowser.isFirefox ? 35 : 25
      this.chartOptions = {
        chart: {
          spacingTop: paddingTop,
          spacingLeft: 0,
          spacingRight: this.data.categories_as_y_labels ? 0 : 20,
          events: {
            render: (evt) => {
              // show last point only if we have an only one serie
              this.initSeries(evt)
              if (evt.target.series.length === 1) {
                const lastPointIndex = evt.target.series[0].data.length - 1
                this.lastPoint = evt.target.series[0].data[lastPointIndex]
                this.showLastPoint()
              }
            },
            click: () => {
              this.hideLastPoint()
            }
          }
        },
        title: {
          text: ''
        },
        colors: this.colors,
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        xAxis: {
          scrollbar: {
            enabled: this.showScroll
          },
          type: 'datetime',
          min: this.startDate,
          max: this.endDate,
          units: [[this.scale, [1]]],
          showFirstLabel: true,
          startOnTick: true,
          showLastLabel: this.scale === 'week',
          dateTimeLabelFormats: {
            month: labelFormat,
            week: labelFormat
          },
          labels: {
            align: 'center',
            style: {
              'text-transform': 'uppercase'
            }
          }
        },
        yAxis: [{
          labels: {
            useHTML: true,
            formatter: function () {
              return `<div class="tw-w-6 tw-text-right tw-uppercase">${this.value}</div>`
            }
          },
          max: this.$yAxisMaxAndMin.max,
          min: this.$yAxisMaxAndMin.min,
          plotBands: this.data.categories_as_y_labels ? [] : this.$yAxisData.plotBands,
          tickPositions: this.$yAxisData.tickPositions,
          title: {
            text: '&nbsp;',
            useHTML: true
          }
        }, {
          className: '',
          opposite: true,
          categories: this.$secondaryCategories,
          tickPositions: this.data.categories_as_y_labels ? this.$yAxisData.tickPositions : [],
          title: {
            text: `<div class="tw-flex tw-text-center tw-items-end">${this.data.y_right_label ? this.data.y_right_label : '&nbsp;'}</div>`,
            useHTML: true
          }
        }],
        legend: {
          enabled: true,
          y: 15
        },
        tooltip: {
          useHTML: true,
          formatter: function () {
            if (!this.point.measure_date_completed) {
              return ''
            }
            const completedDate = moment(this.point.measure_date_completed)
            const name = this.series.name.capitalize()
            const total = this.point.y
            let tooltip = `<div class='progress-tooltip'><b>${name}</b><br>`
            tooltip += `<span class='tooltip-label'>${component.$t('score')}</span>: <span class='value'>${total}</span>`
            if (this.point.scale) {
              tooltip += `<br><span class='tooltip-label'>${component.$t('range')}</span>: <span class='value'>${this.point.scale}</span>`
            }

            if (this.point.cutOff) {
              const label = this.point.cutOffExplanation && this.point.cutOffExplanation.label ? this.point.cutOffExplanation.label : component.$t('cutoff')
              const value = this.point.cutOffExplanation && this.point.cutOffExplanation.text_value ? this.point.cutOffExplanation.text_value : this.point.cutOff
              tooltip += `<br> <span class='tooltip-label'>${label}</span>: <span class='value'>${value}</span>`
            }
            if (this.point.resultSuggestion && this.point.resultSuggestion.length) {
              for (let i = 0; i < this.point.resultSuggestion.length; i++) {
                const rs = this.point.resultSuggestion[i]

                let dataText = `<span class='tooltip-label'>${rs.label}</span>`
                if (rs.data) {
                  const dataValue = isNaN(Number(rs.data)) ? rs.data : component.numberWithDecimals(rs.data, component.numDecimalDigits)
                  dataText += ` : <span class='value'>${dataValue}</span>`
                }
                tooltip += `<br> ${dataText}`
              }
            }
            tooltip += `<br>${completedDate.format('ddd MMM DD, YYYY h:mm A')}`
            tooltip += '</div>'
            return tooltip
          }
        },
        plotOptions: {
          series: {
            marker: {
              symbol: 'circle',
              enabled: true,
              radius: 4,
              states: {
                hover: {
                  radius: 16
                }
              }
            },
            events: {
              legendItemClick: component.legendItemClick
            },
            lineWidth: 1,
            stickyTracking: false,
            connectNulls: true,
            cursor: 'pointer',
            point: {
              events: {
                mouseOver: (evt) => {
                  evt.target.dataLabel.css({ fontSize: '11px' })
                  this.hideLastPoint(evt.target.index)
                },
                mouseOut: (evt) => {
                  evt.target.dataLabel.css({ fontSize: '0px' })
                },
                click: function () {
                  component.goToMeasure(this.measureId, component.chartIndex)
                }
              }
            },
            dataLabels: {
              formatter: function (label) {
                label.x = component.showScroll && this.y < 10 ? -4 : 0
                label.y = component.showScroll && this.y < 10 ? 6 : 10
                return this.y
              },
              style: {
                fontSize: '11px',
                left: '21px',
                color: '#0a3560'
              }
            }
          }
        },

        series: this.series,

        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
              }
            }
          }]
        }
      }
    },
    setChartOptionsPDF () {
      const component = this
      const labelFormat = this.getLabelFormat()
      const paddingTop = this.currentBrowser.isFirefox ? 35 : 25
      this.chartOptionsPDF = {
        chart: {
          spacingTop: paddingTop,
          spacingLeft: 20,
          spacingRight: 20,
          events: {
            render: (evt) => {
              // show last point only if we have an only one series
              this.initSeries(evt)
              if (evt.target.series.length === 1) {
                const lastPointIndex = evt.target.series[0].data.length - 1
                this.lastPoint = evt.target.series[0].data[lastPointIndex]
                this.showLastPoint()
              } else {
                for (let i = 0; i < evt.target.series.length; i++) {
                  const lastPointIndex = evt.target.series[i].data.length - 1
                  this.lastPoint = evt.target.series[i].data[lastPointIndex]
                  this.showLastPointMultiForPDF()
                }
              }
            }
          }
        },
        title: {
          text: ''
        },
        colors: this.colors,
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        xAxis: {
          endOnTick: true,
          maxPadding: 0.17,
          scrollbar: {
            enabled: false
          },
          type: 'datetime',
          units: [[this.scale, [1]]],
          showFirstLabel: true,
          startOnTick: true,
          showLastLabel: this.scale === 'week',
          dateTimeLabelFormats: {
            month: labelFormat,
            week: labelFormat
          },
          labels: {
            align: 'center',
            style: {
              'text-transform': 'uppercase'
            }
          }
        },
        yAxis: [{
          labels: {
            useHTML: true,
            formatter: function () {
              return `<div class="tw-w-6 tw-text-right tw-uppercase">${this.value}</div>`
            }
          },
          max: this.$yAxisMaxAndMin.max,
          min: this.$yAxisMaxAndMin.min,
          plotBands: this.data.categories_as_y_labels ? [] : this.$yAxisData.plotBands,
          tickPositions: this.$yAxisData.tickPositions,
          title: {
            text: '&nbsp;',
            useHTML: true
          }
        }, {
          className: '',
          opposite: true,
          categories: this.$secondaryCategories,
          tickPositions: this.data.categories_as_y_labels ? this.$yAxisData.tickPositions : [],
          title: {
            text: `<div class="tw-flex tw-text-center tw-items-end">${this.data.y_right_label ? this.data.y_right_label : '&nbsp;'}</div>`,
            useHTML: true
          }
        }],
        legend: {
          enabled: true,
          y: 15
        },
        plotOptions: {
          series: {
            animation: false,
            marker: {
              symbol: 'circle',
              enabled: true,
              radius: 4,
              states: {
                hover: {
                  radius: 16
                }
              }
            },
            lineWidth: 1,
            stickyTracking: false,
            connectNulls: true,
            dataLabels: {
              formatter: function (label) {
                label.x = component.showScroll && this.y < 10 ? -4 : 0
                label.y = component.showScroll && this.y < 10 ? 6 : 10
                return this.y
              },
              x: -4,
              y: -2,
              style: {
                fontSize: '11px',
                left: '21px',
                color: '#0a3560'
              }
            }
          }
        },

        series: this.series,

        responsive: {
          rules: [{
            condition: {
              maxWidth: 2480
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
              }
            }
          }]
        }
      }
    },
    getMarkerColor (index) {
      // This is hardcoded until we make a desicion in how to use colors
      return this.colors[index]
      // return '#6787ac'
      // if (val >= 10) {
      //   return 'red'
      // } else if (val >= 8) {
      //   return 'orange'
      // } else {
      //   return 'green'
      // }
    },
    goToMeasure (measureId, chartIndex) {
      this.$router.push({
        name: 'SessionDetail',
        params: {
          ccaId: this.$route.params.ccaId,
          ccauId: this.$route.params.ccauId,
          measureId: this.$getEncodedId(measureId)
        },
        query: {
          chartIndex: chartIndex
        }
      })
    },
    getLabelFormat () {
      const currentYear = moment().format('YYYY')
      const startDate = moment(this.startDate).format('YYYY')
      const endDate = moment(this.endDate).format('YYYY')
      let label = this.scale === 'week' ? '%e %b' : '%b'

      if (startDate !== currentYear || endDate !== currentYear) {
        label += '%y'
      }
      return label
    },
    legendItemClick () {
      if (this.$refs.progressChart) {
        this.$nextTick().then(() => {
          this.$emit('showAllButton', !this.$refs.progressChart.chart.series.every(s => s.visible))
          this.$emit('hideallButton', !this.$refs.progressChart.chart.series.every(s => !s.visible))
        })
      }
    },
    setShowAll (value) {
      this.chartOptions.series.forEach(element => {
        element.visible = value
      })
      this.setChartOptions()
    },
    getDataPointObject (score, serie, measure, color) {
      const cutOff = serie.ycutoff
      const cutOffExplanation = serie.ycutoff_explanation
      return {
        y: this.numberWithDecimals(score, this.numDecimalDigits),
        x: moment(this.$toLocal(measure.measure_date_completed, 'YYYY-MM-DD HH:mm:ss')).startOf('day').valueOf(),
        date: measure.measure_date_completed,
        cutOff: cutOff,
        color: color,
        scale: `${serie.ymin} - ${serie.ymax}`,
        cutOffExplanation: cutOffExplanation,
        resultSuggestion: measure.result_suggestions,
        measureId: measure.client_measure_id,
        measure_date_completed: this.$toLocal(measure.measure_date_completed, 'YYYY-MM-DD HH:mm:ss'),
        measure_date_completed2: measure.measure_date_completed,
        dataLabels: {
          useHTML: true,
          enabled: true,
          crop: false,
          overflow: 'allow'
        },
        marker: {
          lineColor: color,
          fillColor: 'white',
          lineWidth: 3
        }
      }
    },
    getMeasureSeriesData (serie, index) {
      const data = []
      this.data.sessions_progress_results.forEach((measure) => {
        const score = measure.yvalues[index]
        if (score !== null && measure.unanswered_questions === false) {
          data.push(this.getDataPointObject(score, serie, measure, this.getMarkerColor(index)))
        }
      })
      data.sort((a, b) => a.measure_date_completed.localeCompare(b.measure_date_completed))
      return data
    }
  }
}
</script>
