<template>
  <div class="measure-list">
    <overlay
      v-if="routematch || customMeasure"
      :position="position"
      :width="width"
      @close="closeOverlay"
    >
      <template slot="content">
        <div v-if="customMeasure">
          <clinician-customize-measure
            :measure-id="customMeasure"
            @finish="customizationFinished"
          />
        </div>
        <router-view v-else />
      </template>
    </overlay>
    <measure-list-item
      v-if="measure.sessions_completed && loaded"
      :read-only="false"
      :chart-index-init="chartIndexInit"
      :missing-answers="measure.missing_answers"
      :data="measure"
      :compact-sub-menu="true"
      :audition="false"
      class="single-measure-list-item"
      @viewDetail="viewDetail"
      @openEditMeasure="openEditMeasure"
      @measureDisabled="measureDisabled"
    />
    <measure-questions
      v-if="loaded"
      ref="measureQuestionsElement"
      :more-info="measure.more_info"
    />
    <ClinicianEditMeasureResponses
      v-if="measureToEditData"
      :measure="measureToEditData.measure"
      :patient="measureToEditData.patient"
      @finish="finish"
    />
  </div>
</template>
<script>
import MeasureQuestions from '@/components/clinician/patients/measures/MeasureQuestions'
import MeasureListItem from '@/components/clinician/patients/measures/MeasureListItem'
import ClinicianCustomizeMeasure from '@/components/clinician/measures/ClinicianCustomizeMeasure'
import { mapGetters } from 'vuex'
import { PatientHelper } from '@/mixins/PatientHelper'
import { Helpers } from '@/mixins/Helpers'
import Overlay from '@/components/clinician/Overlay'
import ClinicianEditMeasureResponses from '@/components/clinician/measures/ClinicianEditMeasureResponses'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'

export default {
  name: 'SessionDetail',
  components: {
    MeasureQuestions,
    MeasureListItem,
    Overlay,
    ClinicianCustomizeMeasure,
    ClinicianEditMeasureResponses
  },
  mixins: [Helpers, PatientHelper],
  data () {
    return {
      measureToEditData: null,
      chartIndexInit: null,
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      measure: 'getPatientSingleMeasure',
      customMeasure: 'getCustomMeasure',
      formDirty: 'getActiveFormDirty',
      assignment: 'getActiveAssignment'
    }),
    routematch () {
      return this.$route.name === 'ClinicianPatientMeasureDetailFromSessionReport'
    },
    width () {
      return this.customMeasure ? window.innerWidth > 800 ? 800 : window.innerWidth : null
    },
    position () {
      return this.customMeasure ? 'left' : 'right'
    }
  },
  watch: {
    '$route.params.measureId' () {
      this.getData()
    }
  },
  created () {
    this.getData()
    this.setChartIndexInit()
    document.body.classList.add('session-detail-body')
    componentRefreshSingleton.addComponentToRefresh(this.name, this)
  },
  beforeDestroy () {
    document.body.classList.remove('session-detail-body')
    componentRefreshSingleton.removeComponentToRefresh(this.name)
  },
  destroyed () {
    this.$store.dispatch('CLEAR_PATIENT_SINGLE_MEASURE')
  },
  methods: {
    setChartIndexInit () {
      if (this.$route.query.chartIndex) {
        this.chartIndexInit = this.$route.query.chartIndex
      }
    },
    refreshView () {
      this.getData()
    },
    getData () {
      this.loaded = false
      const params = {
        measureId: this.$getDecodedId(this.$route.params.measureId),
        ccauId: this.$getDecodedId(this.$route.params.ccauId)
      }
      this.$store.dispatch('GET_PATIENT_SINGLE_MEASURE', params).then(() => {
        this.loaded = true
      })
      this.$store.dispatch('GET_PATIENTS_MEASURES', params.ccauId)
      this.$store.dispatch('GET_SCHEDULING_OPTIONS', { ccauId: this.$getDecodedId(this.$route.params.ccauId) })
    },
    measureDisabled () {
      const params = {
        measureId: this.$getDecodedId(this.$route.params.measureId),
        ccauId: this.$getDecodedId(this.$route.params.ccauId)
      }

      this.$store.dispatch('GET_PATIENT_SINGLE_MEASURE', params)
      this.$store.dispatch('GET_PATIENTS_MEASURES', params.ccauId)
    },
    viewDetail (d) {
      this.$router.push({ name: 'ClinicianPatientMeasureDetailFromSessionReport', params: { ccauId: this.$route.params.ccauId, ccaId: this.$route.params.ccaId, libraryId: this.$getEncodedId(d.library_id) } })
    },
    closeOverlay () {
      if (this.customMeasure) {
        this.$store.dispatch('SET_CUSTOM_MEASURE', null)
      }
      this.$router.push({ name: 'SessionDetail', params: this.$route.params })
    },
    customizationFinished () {
      this.$toast.success({ message: this.$t('measureUpdated') })
      this.$store.dispatch('SET_CUSTOM_MEASURE', null)
    },
    finish () {
      this.measureToEditData = null
      this.$refs.measureQuestionsElement.fetchMeasure()
      this.getData()
      this.getRespondentLatestSession()
      if (this.formDirty) {
        this.$toast.success({ message: this.$t('measureResponsesUpdated') })
      }
    },
    openEditMeasure () {
      const params = { patient: this.assignment, measure: this.measure }
      params.measure.client_measure_id = params.measure.sessions_completed[0].id
      params.patient.name = params.patient.name || params.patient.full_name
      this.measureToEditData = params
    }
  }
}
</script>
<style lang="scss" scoped>
  @import './../../../../assets/styles/override.scss';

  .measure-list {
    position: relative;
  }
  .icon-close {
    position: absolute;
    top: 8px;
    right: 10px;
    color: $navy-blue;
  }
</style>
