<template>
  <div>
    <!-- PDF template -->
    <div v-if="currentRouteName === 'ClinicianPatientMeasurePDF' || currentRouteName === 'ClinicianPatientPDF'">
      <div v-if="data > 0">
        <div
          v-if="data.chart[0].length === 1"
          class="title"
        >
          <span class="date">
            {{ $toLocal(completedSessions.measure_date_completed, 'MMMM DD, YYYY') }} <span v-if="data.clinical_administered">{{ $t('by') }} {{ completedSessions.updated_by }}</span>
          </span>
          <span>-</span>
          <span>{{ $t('sessionReport') }}</span>
        </div>
      </div>
      <measure-additional-details
        :result-suggestion="resultSuggestion"
        :num-decimal-digits="numDecimalDigits"
      />
    </div>

    <!-- PDF template END -->

    <div v-else>
      <div
        v-if="isSessionReport"
        class="detail"
      >
        <div class="title">
          {{ $t('sessionReport') }}
        </div>
        <span v-if="completedSessions">
          {{ $toLocal(completedSessions.measure_date_completed, 'MMMM DD, YYYY') }} <span v-if="data.clinical_administered">{{ $t('by') }} {{ completedSessions.updated_by }}</span>
        </span>
        <span
          v-if="showPDFIcon"
          class="button-pdf"
        >
          <a
            v-tooltip="getTooltip(data.pdf_status)"
            class="button-pdf"
            :class="{ disabled: isDisabled(data.pdf_status), failed: isFailedClass(data.pdf_status) }"
            @click="getDocumentUrl(data.pdf_uuid, data.pdf_status)"
          ><i
            class="fal"
            :class="isFailed(data.pdf_status)"
          /></a></span>
      </div>
      <measure-due-dates
        v-if="!isSessionReport && !data.discontinued"
        :assignment="assignment"
        :read-only="!isPartOfTeam"
        :frequencies="frequencies"
        :measure-id="data.measure_id"
        :measure-data="data"
        :next-schedules="data.next_schedules"
        :ccau-id="assignment.ccauId"
        :latest-completed-measure="completedSessions ? completedSessions : null"
        @deleteSchedule="deleteSchedule"
      />
      <MeasureDiscontinuedBadge
        v-else-if="data.discontinued"
        :discontinued-last-change="data.discontinued_last_change"
        :replacing-measure="data.replacing_measure"
      />
      <measure-additional-details
        v-if="(isSessionReport || isAuditionReport) && data.sessions_completed.length === 1"
        :result-suggestion="data.chart[0].session_chart[0].result_suggestions"
        :num-decimal-digits="numDecimalDigits"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import MeasureAdditionalDetails from '@/components/clinician/patients/measures/additionalDataTemplates/MeasureAdditionalDetails'
import MeasureDiscontinuedBadge from '@/components/clinician/measures/MeasureDiscontinuedBadge'
import MeasureDueDates from './MeasureDueDates'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { Helpers } from '@/mixins/Helpers'
import { ENDPOINTS } from '@/store/modules/patients/constants/'

const PDF_STATUS_SUCCESSFUL = 'successful'
const PDF_STATUS_PENDING = 'pending'
const PDF_STATUS_FAILED = 'failed'
// eslint-disable-next-line no-unused-vars
const PDF_STATUS_NONE = 'none'

export default {
  name: 'MeasureListItemDetailInfo',
  components: {
    MeasureAdditionalDetails,
    MeasureDiscontinuedBadge,
    MeasureDueDates
  },
  mixins: [DateTimeHelper, Helpers],
  props: ['data', 'assignment', 'date', 'resultSuggestion', 'numDecimalDigits'],

  computed: {
    ...mapGetters({
      frequencies: 'getFrequencies',
      activeAssignment: 'getActiveAssignment'
    }),
    currentRouteName () {
      return this.$route.name
    },
    isPartOfTeam () {
      return !this.activeAssignment.deleted
    },
    isSessionReport () {
      return this.$route.name === 'SessionDetail'
    },
    isAuditionReport () {
      return this.$route.name === 'AuditionMeasureReport'
    },
    completedSessions () {
      return Array.isArray(this.data.sessions_completed) ? this.data.sessions_completed[0] : this.data.sessions_completed
    },
    showPDFIcon () {
      return this.data.is_bundle_measure === 0 &&
      (this.data.pdf_status === PDF_STATUS_SUCCESSFUL ||
      this.data.pdf_status === PDF_STATUS_PENDING ||
      this.data.pdf_status === PDF_STATUS_FAILED)
    }
  },
  methods: {
    editResponses () {
      this.$emit('editResponses')
    },
    deleteSchedule (schedule) {
      this.$emit('deleteSchedule', schedule)
    },
    getDocumentUrl (docId, pdfStatus) {
      if (pdfStatus === PDF_STATUS_SUCCESSFUL) {
        // Make call for PDF URL
        const url = ENDPOINTS.GET_PDF_URL.replace('{pdf_uuid}', docId)
        axios.get(url)
          .then(function (response) {
            // handle success
            const pdfLinkString = response.data
            const pdfUrl = pdfLinkString.replace(/\\\//g, '/')
            window.open(pdfUrl, '_blank')
          })
          .catch(function (error) {
            // handle error
            // eslint-disable-next-line no-console
            console.log(error)
          })
      }
    },
    isDisabled (pdfStatus) {
      return pdfStatus === PDF_STATUS_PENDING
    },
    isFailedClass (pdfStatus) {
      return pdfStatus === PDF_STATUS_FAILED
    },
    isFailed (pdfStatus) {
      return pdfStatus === PDF_STATUS_FAILED ? 'fa-exclamation-triangle' : 'fa-file-pdf'
    },
    getTooltip (pdfStatus) {
      if (pdfStatus === PDF_STATUS_PENDING) {
        return this.$t('measuresAssignedTable.pdfTooltipDisabled')
      } else if (this.data.is_safety_plan) {
        return this.$t('measuresAssignedTable.pdfTooltipSafetyPlan', { patientName: this.assignment.first_name })
      } else if (pdfStatus === PDF_STATUS_FAILED) {
        return this.$t('measuresAssignedTable.pdfTooltipFailed')
      } else {
        return this.$t('measuresAssignedTable.pdfTooltipEnabled', { patientName: this.assignment.first_name })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.in-pdf .title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
</style>
