/* eslint-disable vue/valid-template-root */
<template>
  <div
    v-if="currentRouteName === 'ClinicianPatientMeasurePDF'|| currentRouteName === 'ClinicianPatientPDF'"
    class="for-pdf"
  >
    <div v-if="data">
      <div
        v-if="data.sessions_completed && data.sessions_completed.length"
        class="measure-chart-tabs"
      >
        <div
          v-if="data.completedDataWithScores && data.chart.length === 1"
          class="charts-with-score"
        >
          <MeasureChart
            :key="`${data.abbreviation}_${chartNumber}`"
            :chart-config="selectedChart"
            :measure-data="data"
            :chart-index="chartNumber"
          />
          <div
            v-if="data.chart.length > 0 && data.chart[0].progress_chart.chart_tooltip_text !== null"
            class="measure-chart-footer"
          >
            <h4>
              Additional Information for {{ data.abbreviation }} Progress graph
            </h4>
            <span v-html="data.chart[0].progress_chart.chart_tooltip_text" />
          </div>
        </div>
        <div
          v-else-if="data.completedDataWithScores === 1 && data.chart.length > 1"
          class="charts-with-score"
        >
          <MeasureChart
            v-for="(chart, index) in data.chart"
            :key="index"
            :chart-config="data.chart[index].progress_chart"
            :measure-data="data"
          />
          <div
            v-if="data.chart.length > 0 && data.chart[0].progress_chart.chart_tooltip_text !== null"
            class="measure-chart-footer"
          >
            <h4>
              Additional Information for {{ data.abbreviation }} Progress graph
            </h4>
            <span v-html="data.chart[0].progress_chart.chart_tooltip_text" />
          </div>
        </div>
        <div
          v-else
          class="measure-not-finished pdf"
        >
          <div
            v-if="data.is_bundle_measure && !data.has_graph"
            class="measure-not-score"
          >
            <b>{{ $t('scoringBundle.title') }}</b> {{ $t('scoringBundle.description') }}
          </div>
          <div
            v-else-if="!data.has_graph && !data.is_bundle_measure"
            class="measure-not-score"
          >
            <b>{{ $t('scoringWithNoGraph.title') }}</b> {{ $t('scoringWithNoGraph.description') }}
          </div>
          <div
            v-else-if="messageIsSkippedQuestionMessage"
            class="measure-not-score"
          >
            <b>{{ $t('measuresCantBeScored') }}</b> {{ $t('questionRequiredToScore') }}
          </div>
          <div
            v-else
            class="measure-not-score"
          >
            <b>{{ $t('scoringWithGraphButNoData.title') }}</b> {{ $t('scoringWithGraphButNoData.description') }}
          </div>
        </div>
      </div>
      <div
        v-else
        class="measure-not-finished"
      >
        <div>
          <h4>{{ $t('noResultsFound') }} <small>{{ measureDueDate }}</small></h4>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="data.sessions_completed.length && currentRouteName !== 'ClinicianPatientMeasurePDF' && currentRouteName !== 'ClinicianPatientPDF'"
    class="measure-chart-tabs"
  >
    <div
      v-if="data.completedDataWithScores && data.chart.length"
      class="charts-with-score"
    >
      <ul
        v-if="data.chart.length > 1"
        class="nav nav-tabs"
      >
        <li
          v-for="(tab, index) in data.chart"
          :key="index"
          class="nav-item"
          :class="{active: chartNumber === index}"
        >
          <a
            class="nav-link"
            @click="chartNumber = index"
          >
            {{ tabTitle(tab, index) }}
          </a>
        </li>
      </ul>
      <MeasureChart
        :key="`${data.abbreviation}_${chartNumber}`"
        :chart-config="selectedChart"
        :measure-data="data"
        :chart-index="chartNumber"
      />
    </div>
    <div
      v-else
      class="measure-not-finished"
    >
      <div
        v-if="data.is_bundle_measure && !data.has_graph"
        class="measure-not-score"
      >
        <b>{{ $t('scoringBundle.title') }}</b> {{ $t('scoringBundle.description') }}
      </div>
      <div
        v-else-if="!data.has_graph && !data.is_bundle_measure"
        class="measure-not-score"
      >
        <b>{{ $t('scoringWithNoGraph.title') }}</b> {{ $t('scoringWithNoGraph.description') }} <a
          v-if="$route.name === 'ClinicianPatientMeasures' && !data.is_bundle_measure"
          href="#"
          @click="$emit('viewSessionReport')"
        >{{ $t('scoringWithNoGraph.link') }}</a>
      </div>
      <div
        v-else-if="messageIsSkippedQuestionMessage"
        class="measure-not-score"
      >
        <b>{{ $t('measuresCantBeScored') }}</b> {{ $t('questionRequiredToScore') }}
        <a
          href="#"
          @click="$emit('editResponses', data)"
        >
          {{ $t('clickToEditResponses') }}
        </a>
      </div>
      <div
        v-else
        class="measure-not-score"
      >
        <b>{{ $t('scoringWithGraphButNoData.title') }}</b> {{ $t('scoringWithGraphButNoData.description') }} <a
          v-if="$route.name === 'ClinicianPatientMeasures' && !data.is_bundle_measure"
          href=""
          @click.prevent="$emit('viewSessionReport')"
        >{{ $t('scoringWithGraphButNoData.link') }}</a>
      </div>
    </div>
  </div>
  <div
    v-else
    class="measure-not-finished"
  >
    <div>
      <h4>{{ $t('noResultsFound') }} <small>{{ measureDueDate }}</small></h4>
    </div>
  </div>
</template>

<script>
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import MeasureChart from '@/components/clinician/patients/measures/charts/MeasureChart'

export default {
  name: 'MeasureChartTabs',
  components: {
    MeasureChart
  },
  mixins: [DateTimeHelper],
  props: ['data', 'tabbedChartData', 'nextDueDate', 'chartIndexInit'],
  data () {
    return {
      chartNumber: null,
      charts: []
    }
  },
  computed: {
    messageIsSkippedQuestionMessage () {
      if (
        (this.$route.name === 'ClinicianPatientMeasures' ||
         this.$route.name === 'ClinicianPatientPDF' ||
         this.$route.name === 'ClinicianPatientMeasurePDF') &&
         this.justOneMeasureComplete
      ) {
        return this.data.sessions_completed[0].unanswered_questions
      }
      return false
    },
    justOneMeasureComplete () {
      // eslint-disable-next-line no-unreachable
      return this.data.sessions_completed && this.data.sessions_completed.length === 1
    },
    measureDueDate () {
      let msg = `Measure is due ${this.$toLocal(this.nextDueDate, 'MMMM DD, YYYY')}`
      if (this.$isBeforeToday(this.nextDueDate)) {
        msg = 'Measure is past due'
      }
      return msg
    },
    chartToShowIndex () {
      const length = this.isPDFRoute ? this.data.sessions_completed.length : this.data.completedDataWithScores
      return length === 1 ? 'session_chart' : 'progress_chart'
    },
    // eslint-disable-next-line vue/return-in-computed-property
    selectedChart () {
      if (this.data) {
        const chart = this.data.chart[this.chartNumber]
        if (chart) {
          const length = this.isPDFRoute ? this.data.sessions_completed.length : this.data.completedDataWithScores
          if (length === 1) {
            return chart[this.chartToShowIndex].find(chart => {
              return (chart.show_chart === 'true' || parseInt(chart.show_chart) === 1) && !chart.unanswered_questions
            }) || {}
          }
          return chart[this.chartToShowIndex]
        }
        return {}
      }
    },
    currentRouteName () {
      return this.$route.name
    },
    isPDFRoute () {
      return this.currentRouteName === 'ClinicianPatientMeasurePDF' || this.currentRouteName === 'ClinicianPatientPDF'
    }
  },
  watch: {
    chartNumber (n) {
      this.$emit('setChartSelected', this.selectedChart)
      this.$emit('setChartIndexSelected', n)
    }
  },
  created () {
    this.chartNumber = this.chartIndexInit ? this.chartIndexInit : 0
  },
  methods: {
    tabTitle (tab, index) {
      let tabTitle = ''
      let customTitle = ''
      if (this.data.completedDataWithScores === 1) {
        customTitle = tab[this.chartToShowIndex][0].title
      } else {
        customTitle = tab[this.chartToShowIndex].title
      }
      tabTitle = customTitle ? `${customTitle}` : this.data.abbreviation
      tabTitle += index > 0 && !customTitle ? ' 1' : ''
      return tabTitle
    }
  }
}
</script>
<style lang="scss" scoped>
@import './../../../../../assets/styles/override.scss';
.charts-with-score {
  height: 100%;
  display: flex;
  flex-direction: column;

  .nav-tabs {
    background: #f8fafc;
    border-bottom: 2px solid $light-blue;
    margin: 0 -20px;

    .nav-item {
      color: $navy-blue;
      opacity: 0.5;
      text-align: left;
      border-right: 2px solid $light-blue;
      border-bottom: 2px solid $light-blue;
      margin-bottom: -2px;

      &.active {
        background: $white;
        border-bottom: 2px solid $white;
        opacity: 1;
      }

      a {
        height: 100%;
        display: flex;
        align-items: center;

        &:hover {
          border-color: transparent;
          text-decoration: underline;
          cursor: pointer;
          opacity: .8;
        }
      }
    }
  }
}
.measure-chart-footer {
  font-size: 12px;
  margin-top: 12px;

  h4 {
    font-size:12px;
    font-weight: 700;
    margin-bottom: 0;
  }
}
.for-pdf .measure-not-finished .measure-not-score {
  text-align: center;
  margin: 32px 0;
}
</style>
