<template>
  <div class="dropdown">
    <div
      ref="dropdownMenu"
      class="wrapper"
      :class="{ opened: isOpen, closed: !isOpen }"
    >
      <div
        :class="{readOnly: readOnly, option: true }"
        @click="toggle"
      >
        {{ optionSelected.text }}
      </div>
      <div class="division" />
      <div
        v-show="!confirmationShown"
        class="list"
      >
        <div
          v-for="option in options"
          :key="option.value"
          :class="{readOnly: readOnly, option: true, disabled: optionSelected.value === option.value }"
          @click="updateOption (option)"
        >
          {{ option.text }}
        </div>
      </div>
      <div
        v-show="confirmationShown"
        class="confirm"
      >
        <div class="confirm-list">
          <label
            v-for="item in confirmationOptions"
            :key="item.value"
          >
            <input
              v-model="confirmationSelected"
              type="checkbox"
              :value="item.value"
            >
            <span>{{ item.text }}</span>
          </label>
        </div>
        <button
          class="btn cancel text-uppercase"
          @click="triggerCancel"
        >
          Cancel
        </button>
        <button
          class="btn confirm text-uppercase"
          @click="triggerConfirm"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    selected: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    confirmationOptions: {
      type: Array,
      default: () => []
    },
    open: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isOpen: this.open,
      optionSelected: this.getOptionSelected(),
      confirmationSelected: null,
      confirmationShown: false
    }
  },
  watch: {
    selected () {
      this.optionSelected = this.getOptionSelected()
    }
  },
  created () {
    document.addEventListener('click', this.handleClickOutside)
  },
  methods: {
    getOptionSelected: function () {
      return this.options.find((item) => {
        return item.value === this.selected
      })
    },
    toggle () {
      if (this.readOnly) {
        return
      }
      if (this.confirmationShown) {
        return
      }
      this.isOpen = !this.isOpen
    },
    updateOption (option) {
      if (option === this.optionSelected) {
        return
      }
      this.optionSelected = option
      this.showConfirmation()
    },
    updateConfirmation () {
      this.triggerConfirm()
    },
    handleClickOutside (event) {
      const dropdown = this.$refs.dropdownMenu
      const target = event.target

      if (dropdown && dropdown !== target && !dropdown.contains(target)) {
        if (this.confirmationShown) {
          return
        }
        this.isOpen = false
      }
    },
    showConfirmation () {
      if (this.confirmationOptions.length) {
        this.confirmationShown = true
        return
      }
      this.triggerConfirm()
    },
    triggerConfirm () {
      const data = {
        option: this.optionSelected,
        confirmation: this.confirmationSelected
      }
      this.isOpen = false
      this.confirmationShown = false
      this.confirmationSelected = null
      this.$emit('onChange', data)
    },
    triggerCancel () {
      this.confirmationShown = false
      this.optionSelected = this.getOptionSelected()
      this.isOpen = false
      this.confirmationSelected = null
    }
  }
}
</script>

<style lang="scss" scoped>
  $spacingTopBottom: 5px;
  $spacingLeftRight: 8px;
  $border: 1px;
  $width : 240px;

  .dropdown {
    position: relative;
    height: 10px + $spacingTopBottom;
  }

  .wrapper {
    position: absolute;
    background: #FFF;
  }

  .wrapper.opened {
    width: $width;
    border-radius: 3px;
    border: solid $border #899cb5;
    padding: $spacingTopBottom $spacingLeftRight;
    margin-top: ($spacingTopBottom + $border) * -1;
    margin-left: ($spacingLeftRight + $border) * -1 ;
    z-index: 1;
  }

  .wrapper.closed {
    .division {
      display: none;
    }

    .list {
      display: none;
    }

    .confirm {
      display: none;
    }
  }

  .division {
    background: #e8eef4;
    height: 2px;
    width: 100%;
    padding-left: $spacingLeftRight;
    padding-right: $spacingLeftRight;
    margin-top: $spacingTopBottom;
    margin-bottom: $spacingTopBottom;
  }

  .option:not(.readOnly) {
    display: block;
    cursor: pointer;
    padding: $spacingTopBottom 0;
  }

  .option.disabled {
    color: #b8c3d1;
  }

  .list {
    .option:hover {
      background: #eefcfb;
    }
  }
</style>
