
<template>
  <div class="administered-measure-completion-data pdf-download">
    <p> {{ $t('lastUpdated') }} </p>
    <span>{{ $toLocal(measureData.last_update_date, 'MMM DD, YYYY') }} {{ $t('by') }} {{ measureData.updated_by }}</span>
    <span
      v-if="showPDFIcon"
      class="button-pdf"
    >
      <a
        v-tooltip="getTooltip(measure.pdf_status)"
        :class="{ disabled: isDisabled(measure.pdf_status), failed: isFailedClass(measure.pdf_status) }"
        @click="getDocumentUrl(measure.pdf_uuid, measure.pdf_status)"
      ><i
        class="fal"
        :class="isFailed(measure.pdf_status)"
      /></a></span>
    <div v-if="!measures">
      <ClinicalAdministeredButton
        v-if="measure.clinical_administered"
        :measure="measureParams"
        :is-update="measure.is_pre_populate"
        :schedule-new="true"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import ClinicalAdministeredButton from '@/components/clinician/patients/measures/ClinicalAdministeredButton'
import { Helpers } from '@/mixins/Helpers'
import { ENDPOINTS } from '@/store/modules/patients/constants/'

const PDF_STATUS_SUCCESSFUL = 'successful'
const PDF_STATUS_PENDING = 'pending'
const PDF_STATUS_FAILED = 'failed'
// eslint-disable-next-line no-unused-vars
const PDF_STATUS_NONE = 'none'

export default {
  name: 'AdministeredMeasureCompletionData',
  components: { ClinicalAdministeredButton },
  mixins: [DateTimeHelper, Helpers],
  props: ['measureData', 'measures', 'measure', 'assignment'],
  computed: {
    measureParams () {
      return Object.assign({}, this.measure, this.measureData)
    },
    showPDFIcon () {
      return this.measure.is_safety_plan === true &&
      (this.measure.pdf_status === PDF_STATUS_SUCCESSFUL ||
      this.measure.pdf_status === PDF_STATUS_PENDING ||
      this.measure.pdf_status === PDF_STATUS_FAILED)
    }
  },
  methods: {
    getDocumentUrl (docId, pdfStatus) {
      if (pdfStatus === PDF_STATUS_SUCCESSFUL) {
        const url = ENDPOINTS.GET_PDF_URL.replace('{pdf_uuid}', docId)
        axios.get(url)
          .then(function (response) {
            const pdfLinkString = response.data
            const pdfUrl = pdfLinkString.replace(/\\\//g, '/')
            window.open(pdfUrl, '_blank')
          })
          .catch(function (error) {
            // TODO handle error
            // eslint-disable-next-line no-console
            console.log(error)
          })
      }
    },
    isDisabled (pdfStatus) {
      return pdfStatus === PDF_STATUS_PENDING
    },
    isFailed (pdfStatus) {
      return pdfStatus === PDF_STATUS_FAILED ? 'fa-exclamation-triangle' : 'fa-file-pdf'
    },
    isFailedClass (pdfStatus) {
      return pdfStatus === PDF_STATUS_FAILED
    },
    getTooltip (pdfStatus) {
      if (pdfStatus === PDF_STATUS_PENDING) {
        return this.$t('measuresAssignedTable.pdfTooltipDisabled')
      } else if (this.measure.is_safety_plan) {
        return this.$t('measuresAssignedTable.pdfTooltipSafetyPlan', { patientName: this.assignment.first_name })
      } else if (pdfStatus === PDF_STATUS_FAILED) {
        return this.$t('measuresAssignedTable.pdfTooltipFailed')
      } else {
        return this.$t('measuresAssignedTable.pdfTooltipEnabled', { patientName: this.assignment.first_name })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.administered-measure-completion-data {
  margin-bottom: 20px;
  p {
    margin-bottom: 5px;
    box-sizing: border-box;
    font-family: MuseoSans_300;
    font-weight: 300;
    text-align: left;
    line-height: normal;
    text-transform: uppercase;
    font-size: 12px;
  }
  span {
    box-sizing: border-box;
    font-family: MuseoSans_500;
    font-weight: 500;
    text-align: left;
    font-size: 16px;
  }
  > div {
    .clinical-administered-button {
      margin-top: 20px;
      padding-right: 40px;
      padding-left: 40px;
    }
  }
}
</style>
